import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Skeleton, Table } from "antd";
import React from "react";
import { } from "src/pages/binOverview/BinCommander";
import { useBinInfoContext } from "src/queries/BinInfoContext";
import { binDBKeys, ExtendedColumnType, useColumnExpansion } from 'src/pages/binOverview/BinCommander';
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Line } from "react-chartjs-2";
import { last } from "lodash";
import { BaseChartProps } from "src/pages/dashboard/charts/TemperatureCableChart";
import { useMachineAnnotations } from "./useMachineStatusAnnotations";
import ShivversService from "src/api/ShivversService";
import { RangeValue } from "src/pages/dashboard/charts/ChartContainer";
dayjs.extend(customParseFormat);

export const GetShivversMoistureData = async (ip: string, params?: { from: Dayjs | null | undefined, to: Dayjs | null | undefined }, signal?: AbortSignal) => {

    const readings = await ShivversService.readings(ip, params?.from?.format?.(), params?.to?.format?.(), signal);

    return Promise.resolve({
        "data": [...readings],
    });
}
export type ReadingsDataType = Awaited<ReturnType<typeof GetShivversMoistureData>>['data'][0];

const generateSubTitle = (startDate: string | null | undefined, endDate: string | null | undefined): string => {
    if (startDate == null || endDate == null) {
        return '';
    }
    return `${dayjs(startDate).format('MM/DD/YYYY')} - ${dayjs(endDate).format("MM/DD/YYYY")}`;
}

export const MoistureDisplayChart = (props: BaseChartProps) => {

    const queryClient = useQueryClient();
    const value = props.value;
    const binInfo = useBinInfoContext();

    const chartQuery = useQuery({
        queryKey: [...binDBKeys.moistureDisplay(binInfo.linkIpAddress!), { startDate: value?.[0], endDate: value?.[1] }],
        staleTime: Infinity,
        keepPreviousData: true, retry: 0,
        queryFn: async (q) => await GetShivversMoistureData(binInfo.linkIpAddress!, {
            from: value?.[0],
            to: value?.[1],
        }, q.signal),
        select: (data) => {
            const transformed = data?.data?.map(entry => {
                return { ...entry, x: entry.dateinUtc };
            })
            return {
                data: transformed,
            };
        },
        enabled: (props.binId ?? 0) > 0,
    });

    const statusAnnotations = useMachineAnnotations(chartQuery.data?.data ?? []);

    return <>

        <Skeleton loading={chartQuery.isLoading}>
            <section style={{ width: "100%", minHeight: props.chartHeight ?? "50vh" }}>
                {chartQuery.data && <Line options={{
                    scales: {
                        x: {
                            type: "time",
                            //min: value?.[0]?.toISOString(),
                            //max: value?.[1]?.toISOString(),
                            time: {
                                minUnit: 'hour',
                                displayFormats: {
                                }
                            },
                        },
                        y: {
                            type: 'linear',
                            position: 'left',
                            suggestedMin: 14,
                            suggestedMax: 15.5,
                            ticks: {
                                precision: 1,
                            },
                            title: {
                                font: {
                                    size: 16,
                                },
                                display: false,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            position: 'top' as const,
                            display: true,
                        },
                        title: {
                            display: true,
                            text: 'Moisture Graph',
                        },
                        subtitle: {
                            display: chartQuery.data?.data?.length > 1,
                            text: generateSubTitle(chartQuery.data?.data?.[0]?.dateinUtc, last(chartQuery.data?.data)?.dateinUtc),
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                            }
                        },
                        annotation: {
                            annotations: [
                                ...statusAnnotations,
                            ],
                        },
                    },
                }} data={{
                    datasets: [

                        {
                            type: 'line',
                            data: chartQuery.data.data ?? [], label: "Moisture", indexAxis: 'x',
                            parsing: {
                                yAxisKey: 'moistread',
                                xAxisKey: 'datein'
                            },
                            spanGaps: true,
                            backgroundColor: "#2933f2",
                            borderColor: "#2933f2",
                        },
                        {
                            type: 'line',
                            // @ts-ignore - different structure than 1st dataset
                            data: chartQuery.data?.data ?? [],
                            label: "Moisture Average",
                            indexAxis: 'x',
                            parsing: {
                                yAxisKey: 'moistavg',
                                xAxisKey: 'datein'
                            },
                            spanGaps: true,
                            stepped: true,
                            backgroundColor: "#07ff4d",
                            borderColor: "#07ff4d",
                        },
                    ]
                }} />}
            </section>
        </Skeleton>

    </>;
};

export const TemperatureDisplayChart = (props: BaseChartProps) => {

    const queryClient = useQueryClient();
    const value = props.value;
    const binInfo = useBinInfoContext();

    const chartQuery = useQuery({
        queryKey: [...binDBKeys.moistureDisplay(binInfo.linkIpAddress!), { startDate: value?.[0], endDate: value?.[1] }],
        staleTime: Infinity,
        keepPreviousData: true, retry: 0,
        queryFn: async (q) => await GetShivversMoistureData(binInfo.linkIpAddress!, {
            from: value?.[0],
            to: value?.[1],
        }, q.signal),
        select: (data) => {
            const transformed = data?.data?.map(entry => {
                return {
                    ...entry, x: dayjs(entry.dateinUtc).toISOString(),

                };
            })
            return {
                data: transformed,
            };
        },
        enabled: (props.binId ?? 0) > 0,
    });
    const statusAnnotations = useMachineAnnotations(chartQuery.data?.data ?? []);


    return <>

        <Skeleton loading={chartQuery.isLoading}>
            <section style={{ width: "100%", minHeight: props.chartHeight ?? "50vh" }}>
                {chartQuery.data && <Line options={{
                    scales: {
                        x: {
                            type: "time",
                            //min: value?.[0]?.toISOString(),
                            //max: value?.[1]?.toISOString(),
                            time: {
                                minUnit: 'hour',
                                displayFormats: {
                                }
                            },
                        },
                        y: {
                            type: 'linear',
                            position: 'left',
                            suggestedMin: 0,
                            //suggestedMax: 15.5,
                            ticks: {
                                precision: 0,
                                stepSize: 10,
                            },
                            title: {
                                font: {
                                    size: 16,
                                },
                                display: false,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            position: 'top' as const,
                            display: true,
                        },
                        title: {
                            display: true,
                            text: 'Temperature Graph',
                        },
                        subtitle: {
                            display: chartQuery.data?.data?.length > 1,
                            text: generateSubTitle(chartQuery.data?.data?.[0]?.dateinUtc, last(chartQuery.data?.data)?.dateinUtc),
                        },
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                            }
                        },
                        annotation: {
                            annotations: [
                                ...statusAnnotations,
                            ],
                        },
                    },
                }} data={{
                    datasets: [

                        {
                            type: 'line',
                            data: chartQuery.data.data ?? [], label: "Target Temp", indexAxis: 'x',
                            parsing: {
                                yAxisKey: 'targettemp',
                                xAxisKey: 'dateinUtc'
                            },
                            spanGaps: true,
                            backgroundColor: "#2933f2",
                            borderColor: "#2933f2",
                        },
                        {
                            type: 'line',
                            // @ts-ignore - different structure than 1st dataset
                            data: chartQuery.data?.data ?? [],
                            label: "Plenum Temp",
                            indexAxis: 'x',
                            parsing: {
                                yAxisKey: 'plenumtemp',
                                xAxisKey: 'dateinUtc'
                            },
                            spanGaps: true,
                            stepped: true,
                            backgroundColor: "#07ff4d",
                            borderColor: "#07ff4d",
                        },
                        {
                            type: 'line',
                            // @ts-ignore - different structure than 1st dataset
                            data: chartQuery.data?.data ?? [],
                            label: "Grain Temp",
                            indexAxis: 'x',
                            parsing: {
                                yAxisKey: 'graintemp',
                                xAxisKey: 'dateinUtc'
                            },
                            spanGaps: true,
                            stepped: true,
                            backgroundColor: "#000000",
                            borderColor: "#000000",
                        },
                    ]
                }} />}
            </section>
        </Skeleton>

    </>;
};

export const MoistureDisplay = (props: { value: RangeValue; }) => {
    const binInfo = useBinInfoContext();
    const moistureQuery = useQuery({
        queryFn: async (q) => await GetShivversMoistureData(binInfo.linkIpAddress!, {
            from: props.value?.[0],
            to: props.value?.[1],
        }, q.signal),
        queryKey: [...binDBKeys.moistureDisplay(binInfo.linkIpAddress!), { startDate: props?.value?.[0], endDate: props?.value?.[1] }],
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    })
    const columns: Array<ExtendedColumnType<Awaited<ReturnType<typeof GetShivversMoistureData>>['data'][0]>> = [
        {
            title: "Datein",
            dataIndex: "dateinUtc",
            sorter: (a, b) => {
                return dayjs(a.dateinUtc).diff(b.dateinUtc);
            },
            render(value, record, index) {
                return <span>{dayjs(value).format("hh:mm A MM/DD/YYYY")}</span>
            },
            sortType: 'date',
            defaultSortOrder: "descend",
        },
        {
            title: "Moisture",
            dataIndex: "moistread",
        },
        {
            title: "GrnTemp",
            dataIndex: "graintemp",
        },
        {
            title: "MoistAvg",
            dataIndex: "moistavg",
        },
        {
            title: "Status",
            dataIndex: "machine",
            filters: [
                {
                    text: "ON",
                    value: "ON"
                },
                {
                    text: "OFF",
                    value: "OFF"
                }
            ],
            onFilter: (value, record) => value.toString().trim() === record.machine?.trim(),
        },
        {
            title: "Target",
            dataIndex: "targettemp",
        },
        {
            title: "Plenum",
            dataIndex: "plenumtemp",
        },
    ];

    const transformedColumns = useColumnExpansion({
        columns: columns, datasource: moistureQuery.data?.data ?? []
    })

    return <Table rowKey={row => row.dateinUtc} size="small" pagination={{ defaultPageSize: 10, size: "default" }} dataSource={moistureQuery.data?.data ?? []} columns={transformedColumns} />
}