import Table, { ColumnProps } from "antd/es/table";
import { useBinDTOContext } from "src/queries/BinDTOContext"
import { formatNumber } from "../../HeaterControls";
import _ from "lodash";
import React from "react";
import { Card, Flex } from "antd";
import { SensorDataType } from "./TemperatureCableTable";
import { CableSensorDataTranposed, DataTransformer, formatCableIdShorthand } from "./dataTransformation";
import BinDTO from "src/models/BinDTO";
import RhSensorEnum from "src/consts/RhSensorEnum";
import { SensorNameWidth, SensorNumberWidth } from "../shared";

export const MoistureCableTable = (props: {}) => {

    const binDTOContext = useBinDTOContext();
    const normalizedData = useTranformMoistureSensorData(binDTOContext.binDTO!);

    if (binDTOContext.binDTO == null) {
        console.log("bindto is null in temperatureCableTable");
        return null;
    }

    const columns: ColumnProps<SensorDataType[0]>[] = [];
    columns.push({
        width: SensorNumberWidth,
        align: "center",
        dataIndex: ["number"],
        render(value, record, index) {
            return <span style={{ fontWeight: "bold" }}>{value}</span>
        },
    })

    for (const [cableIndex, cable] of (normalizedData.cableIds ?? [])?.entries()) {
        columns.push({
            title: cable.shortDisplayName,
            width: SensorNameWidth,
            align: "center",
            dataIndex: ["sensors", cableIndex, "temperature"],
            render(value, record, tableIndex) {
                return <span>{formatNumber(value, { decimalPlaces: 0, suffix: "%", filler: "" })}</span>;
            },
        });
    }

    if (normalizedData.cableIds.length === 0) {
        return null;
    }

    return <Card styles={{ body: { padding: 0, overflow: "auto" } }} size="small" title={`Moisture Cable Readings (MC%)`}>
        <div style={{ minWidth: 128 }}>
            <Table tableLayout="fixed" size="small" pagination={false} dataSource={normalizedData.sensorData} columns={columns}
                scroll={{ x: "max-content" }}
                style={{ tableLayout: "fixed", overflowX: 'auto', width: "max-content", minWidth: "max-content" }}
            />
        </div>
    </Card>
}

export const useTranformMoistureSensorData = (bindto: BinDTO): CableSensorDataTranposed => {

    const dataTransformer = new DataTransformer();
    if (bindto == null) {
        return {
            cableIds: dataTransformer.cableIds,
            sensorData: dataTransformer.sensorLayers,
        };
    }

    if (bindto.rhSensorsType === RhSensorEnum.Opi) {
        for (let [cableIndex, cable] of bindto.opiMoistureCables?.entries() ?? []) {
            dataTransformer.populateCableInfo(cable);
            for (let [sensorIndex, sensor] of cable?.rhtStates?.entries() ?? []) {
                dataTransformer.populateSensor(cable, sensor);
            }
        }
    }

    else if (bindto.rhSensorsType === RhSensorEnum.BinSense) {
        for (let [cableIndex, cable] of bindto.binSenseMoistureCables?.entries() ?? []) {
            dataTransformer.populateCableInfo(cable);
            for (let [sensorIndex, sensor] of cable?.rhtStates?.entries() ?? []) {
                dataTransformer.populateSensor(cable, sensor);
            }
        }
    }

    else if (bindto.rhSensorsType === RhSensorEnum.PowerCast) {
        for (let [cableIndex, cable] of bindto.moistureCables_RF?.entries() ?? []) {
            dataTransformer.populateCableInfo(cable);
            for (let [sensorIndex, sensor] of cable?.wirelessStates?.entries() ?? []) {
                dataTransformer.populateSensor(cable, sensor);
            }
        }
    }

    dataTransformer.sensorLayers.reverse();

    return {
        cableIds: dataTransformer.cableIds,
        sensorData: dataTransformer.sensorLayers,
    };
}