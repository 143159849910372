import * as React from 'react';
// import DataTable, { DataTableColumnProps } from 'src/pages/shared/DataTable';
import FormattedPhone from 'src/pages/shared/FormattedPhone';
import Role from 'src/consts/Role';
import RoleUtil from 'src/utils/RoleUtil';
import UserApiService from 'src/api/UserApiService';
import UserDetailDTO from '../../models/UserDetailDTO';
import { BreadcrumbsItem } from '../shared/GlobalBreadcrumb';
import {
    Col,
    notification,
    Row,
    Spin,
    Form,
    Select,
    Descriptions,
    Button,
    Card,
    Input,
    Alert,
    InputNumber,
    Switch,
    Modal,
    message,
    Space,
    FormInstance,
    ModalProps,
    ModalFuncProps
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { RouteComponentProps } from 'react-router-dom';
import FormattedAddress from '../shared/FormattedAddress';
import { RequiredRule } from 'src/consts/FormConstants';
import AccountApiService from 'src/api/AccountApiService';
import GrowerDTO from 'src/models/GrowerDTO';
import StatePicker from '../shared/StatePicker';
import PhoneInput from '../shared/PhoneInput';
import HistoryUtil from 'src/utils/HistoryUtil';
import RoleApiService from 'src/api/RoleApiService';
import RoleDTO from 'src/models/RoleDTO';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import FormItem from 'antd/es/form/FormItem/index';
import { ModalFunc } from 'antd/es/modal/confirm';
import { ExternalUserEdit, ExternalUserEditContainer } from './ExternalUserEdit';
const { Option } = Select;
const { Item } = Descriptions;

interface DeleteProps {
    username: string;
    open: boolean;
}

const DeleteConfirmation = (props: DeleteProps & ModalProps) => {

    const [inputValue, setInputValue] = React.useState('');
    const [userEqual, setUserEqual] = React.useState(false);

    React.useEffect(() => {
        if (props.username == null) {
            setUserEqual(false);
            return;
        }
        const InputAndUsernameMatch = inputValue === props.username;
        setUserEqual(InputAndUsernameMatch);

    }, [inputValue, props.username]);
    
    function handleInputChange(event: any) {
        const userInput = event!.target!.value;
        setInputValue(userInput);        
    }

    function handleEnterPressUserConfirm(event: any) {
        if (!userEqual) {
            return;
        }
        props.onOk?.(event);
    }

    return (
        <Modal {...props} title="Are you ABSOLUTELY sure?" open={props.open}
        footer={[
            <Button ghost key="back" onClick={props.onCancel as any} >
            Cancel
          </Button>,
        <Button onClick={handleEnterPressUserConfirm} key="submit" type="primary" danger disabled={!userEqual}>I understand the consequences, delete this user</Button>
        ]}
        >
            <span>This action <b>CANNOT</b> be undone. This will delete the user <b>{props.username}</b>. All their user info will be lost. </span>
            <br />
            <br />

            <label><span>Please type the username again to confirm.</span>
                <Input autoFocus={true} id="user-to-delete" defaultValue={inputValue} onChange={handleInputChange} onPressEnter={handleEnterPressUserConfirm} />
            </label>
        </Modal>
    );
};

interface ChangePasswordProps {
    user: UserDetailDTO;
    open: boolean;
    onConfirm: () => void;
}

const formItemLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

interface ChangePasswordAdminFormProps {
    confirm: string;
    newPassword: string;
}

export const ChangeAnotherUserPasswordModal = (props: ChangePasswordProps & ModalProps) => {

    const [inputValue, setInputValue] = React.useState('');

    const [form] = Form.useForm();

    const [userEqual, setUserEqual] = React.useState(false);

    React.useEffect(() => {
        if (props.user.username == null) {
            setUserEqual(false);
            return;
        }
        const InputAndUsernameMatch = inputValue === props.user.username;
        setUserEqual(InputAndUsernameMatch);

    }, [inputValue, props.user.username]);

    function handleInputChange(event: any) {
        const userInput = event!.target!.value;
        setInputValue(userInput);        
    }

    function onFinish(v: ChangePasswordAdminFormProps) {
        console.log(v);
        AccountApiService.changePasswordAdminOverride(props.user.id, v.newPassword)
            .then((res) => {
                message.success('changed password');
                props.onConfirm();
            })
            .catch((err) => {
                message.error(`failed to change password: ${err.message}`);
        });
    }

    return (
        <Modal {...props} title={`Change password for ${props.user.username}?`} open={props.open}
        footer={[
            <Button key="back" onClick={props.onCancel as any}>
            Cancel
          </Button>,
        <Button key="submit" form="password-change-form" htmlType="submit" type="primary" danger disabled={!userEqual} onSubmit={(e) => e.preventDefault()}>Change User's Password</Button>
        ]}
        >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Row>
            <Col>
            <span>This action will change the password of the user <b>{props.user.username}</b>.
            <br />
            <Alert message="You must let the user know of their new password" type="warning" showIcon/>
            </span>
            <br />
            <label><span>Please type the username again to confirm.</span>
                <Input autoFocus={true} id="change-password-for-username" defaultValue={inputValue} onChange={handleInputChange} />
            </label>
            </Col>
            </Row>

            <Row>
            <Col>
            <Form form={form} id="password-change-form"
            onFinish={onFinish}
            >
            <Form.Item
                    {...formItemLayout}
                    name="newPassword"
                    label="New Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password!',
                        },
                    ]}>
                    <Input.Password required={true} minLength={1} autoComplete="new-password" />
                </Form.Item>
                <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        {...formItemLayout}
                        dependencies={['newPassword']}
                        rules={[
                            {
                                required: true,
                                message: 'Confirm New Password is required',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule: any, value: string | null) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match');
                                },
                            }),
                        ]}>
                        <Input.Password required={true} minLength={1} autoComplete="new-password" />
                    </Form.Item>
            </Form>
            </Col>
            </Row>
            </Space>
        </Modal>
    );
};

interface UserDetailProps {
    id: string;
}

interface UserDetailState {
    loading: boolean;
    userId: number;
    user?: UserDetailDTO;
    roles: RoleDTO[];
    userRoles: number[];
    growers: [number, string][];
    edit: boolean;
    resetSuccess: boolean;
    error: any;
    state: string | null;
    changeUserPasswordVisible: boolean;
    deletePromptVisible: boolean;
}

class UserDetail extends React.Component<RouteComponentProps<UserDetailProps>, UserDetailState> {
    private formRef: FormInstance | undefined;
    // private view: string = 'info';
    // private layoutForm = {
    //     labelCol: { xs: 24, sm: 8, md: 8, lg: 8, xl: 8, xxl: 8 },
    //     wrapperCol: { xs: 24, sm: 16, md: 16, lg: 16, xl: 16, xxl: 16 },
    // };

    constructor(props: RouteComponentProps<UserDetailProps>) {
        super(props);

        let id = props.match == null ? '' : props.match.params.id;

        this.state = {
            userId: parseInt(id, 10),
            roles: [],
            userRoles: [],
            growers: [],
            loading: true,
            edit: false,
            resetSuccess: false,
            error: null,
            state: null,
            changeUserPasswordVisible: false,
            deletePromptVisible: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    showDeletePrompt = () => {
        this.setState({deletePromptVisible: true});
    }

    hideDeletePrompt = () => {
        this.setState({deletePromptVisible: false});
    }

    deleteUser = () => {
        AccountApiService.deleteUser(this.state.user!.id).then(_ => {
            this.hideDeletePrompt();
            message.success(`Deleted ${this.state.user!.username}`);
            // Go back to user select list, since this user no longer exists
            HistoryUtil.goBack();
        })
        .catch((err) => {
            message.error('Failed to delete user');
            console.log(err, "failed to delete user");
        });
    }

    enableUserPasswordModal = () => {
        this.setState({changeUserPasswordVisible: true});
    }

    disableUserPasswordModal = () => {
        this.setState({changeUserPasswordVisible: false});
    }

    render() {
        const { user, loading, edit, error, resetSuccess } = this.state;

        if (loading) {
            return null;
        }

        if ((user?.externalLogins?.length ?? 0) > 0) {
            return <ExternalUserEditContainer edit={true} key={user?.id} user={user!} roles={this.state.roles} />
        }
        // let growers = user?.growers;
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        let alert: JSX.Element | null = null;

        if (error !== null) {
            let description = 'An error occurred while trying to send the reset password email.';
            if (error.message) {
                description = error.message;
            }
            alert = (
                <Alert
                    message="Error"
                    description={description}
                    type="error"
                    showIcon={true}
                    style={{ marginBottom: '12px' }}
                />);
        }
        if (resetSuccess) {
            let description = 'An email with a link to reset your password was sent to the email to provided.';
            alert = (
                <Alert
                    message="Success"
                    description={description}
                    type="success"
                    showIcon={true}
                    style={{ marginBottom: '12px' }}
                />);
        }
        // const columns = { xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 4 };
        const isAdmin = RoleUtil.currentUserIsAdmin();


        return (
            <div className="content-background" style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
                <div className="content-container">
                    <BreadcrumbsItem name="user">
                        {`${user?.firstName} ${user?.lastName}`}
                    </BreadcrumbsItem>

                    <Spin spinning={loading}>

                        {isAdmin ? <PageHeader
                            title="User Info"
                            onBack={() => { HistoryUtil.goBack(); }}
                            extra={<Button key="edit" onClick={() => {
                                this.setState({ edit: !edit });
                            }}>Edit</Button>} />
                            : <PageHeader title="User Info" />}

                        <Descriptions style={{ fontWeight: 'bold' }}  >
                            <Item label="First Name" >
                                {user?.firstName}
                            </Item>
                            <Item label="Last Name">
                                {user?.lastName}
                            </Item>
                            <Item label="User Active">
                                {`${user?.isActive}`}
                            </Item>
                            <Item label="Phone">
                                <FormattedPhone phone={user?.phone} />
                            </Item>
                            <Item label="Email">
                                {user?.email}
                            </Item>
                            <Item label="Address" {...layout}>
                                <FormattedAddress {...user} />
                            </Item>
                        </Descriptions>
                        <Row>
                            {(user?.growers || []).map((grower: GrowerDTO, i: number) => {
                                return (<Card key={i} style={{ marginBottom: 16 }}>
                                    <Descriptions>
                                        <Item label="Organization Name">{grower.growerName}</Item>
                                        <Item label="Organization Email">{grower.growerEmail}</Item>
                                        <Item label="Notes">{grower.notes}</Item>
                                    </Descriptions>
                                </Card>);
                            })}
                        </Row>
                        {isAdmin && !loading && (user?.externalLogins?.length ?? 0) === 0 && (<Row style={{gap: '16px'}}>
                            <Button onClick={this.resetPassword} >Reset Password</Button>{alert}
                            <Button onClick={this.showDeletePrompt} type="primary" danger >Delete User</Button>

                            <Button type="primary" danger onClick={this.enableUserPasswordModal} >Change User's Password</Button>
                            <ChangeAnotherUserPasswordModal open={this.state.changeUserPasswordVisible} user={this.state.user!} onCancel={this.disableUserPasswordModal} onConfirm={this.disableUserPasswordModal}
                             />

                            {/* destroyOnClose so that input is refocused to user to delete on every launch*/}
                            <DeleteConfirmation destroyOnClose={true} open={this.state.deletePromptVisible} username={this.state.user?.username ?? ''}
                                onOk={this.deleteUser} onCancel={this.hideDeletePrompt}
                                 />
                            </Row>)}
                        {isAdmin && user && edit && this.renderEditableUserInfo()}
                    </Spin>
                </div>
            </div>
        );
    }

    private fetchData = () => {
        this.setState({ loading: true });
        Promise.all([
            UserApiService.getUser(this.state.userId),
            RoleApiService.getRoles(),
            RoleApiService.getRolesByUser(this.state.userId),
            EnterpriseApiService.getGrowerIDs()
        ]).then(([user, roles, userRoles, growerIDs]) => {
            let userRolesNumbers = userRoles.map((role) => { return role.id; });
            let GrowerNameIDPairs: [number, string][] = growerIDs.map((grower) => [grower.growerID, grower.growerName ? grower.growerName : 'error: no name']);
            this.setState(
                {
                    user,
                    roles,
                    userRoles: userRolesNumbers,
                    growers: GrowerNameIDPairs,
                    loading: false,
                    state: user.state || null
                }

            );
        }).catch(error => {
            this.setState({ loading: false });
            notification.error({
                message: error.message,
                description: error.description
            });
        });
    }

    private renderEditableUserInfo = () => {
        const { user } = this.state;
        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        };
        console.log("user external logsin", user);
        if (user?.externalLogins?.length) {
            return <ExternalUserEdit edit={true} roles={this.state.roles} user={user} />
        }
        return (
            <Row style={{ marginTop: '24px' }}>

                <Col xxl={12} xl={12} lg={16} md={24} sm={24} xs={24}>
                    <Card title="User Information">
                        {user &&
                            <Form
                                initialValues={
                                    {
                                        username: user.username,
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        email: user.email,
                                        phone: user.phone,
                                        isActive: user.isActive,
                                        addressLine1: user.addressLine1,
                                        addressLine2: user.addressLine2,
                                        city: user.city,
                                        state: user.state,
                                        zip: user.zip,
                                        roles: this.state?.userRoles,
                                        growers: user.growers?.map((grower) => { return grower.growerID; })
                                    }
                                }
                                onFinish={this.saveChanges}
                                form={this.formRef}
                                layout="horizontal"
                            // validateMessages={BasicValidateMessages}
                            >
                                <FormItem {...layout} label="User Name" name="username" rules={[RequiredRule]}>
                                    <Input />
                                </FormItem>
                                <FormItem {...layout} label="First Name" name="firstName" rules={[RequiredRule]}>
                                    <Input />
                                </FormItem>
                                <FormItem {...layout} label="Last Name" name="lastName" rules={[RequiredRule]}>
                                    <Input />
                                </FormItem>
                                <FormItem {...layout} label="Is Active" name="isActive" rules={[RequiredRule]}>
                                    <Switch defaultChecked={user.isActive} />
                                </FormItem>
                                <Form.Item label="Organization(s)" name="growers" rules={[]} {...layout}>
                                    <Select mode="multiple" style={{ maxWidth: '200px' }}>
                                        {
                                            this.state.growers?.map((grower, index) => (
                                                <Option key={index} value={grower[0]}>
                                                    {grower[1]}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <FormItem {...layout} label="Role" name="roles" /*{...this.layoutForm}*/  >
                                    <Select mode="multiple" style={{ maxWidth: '200px' }}>
                                        {
                                            this.state.roles?.map((role, index) => (
                                                <Option key={index} value={role.id}>
                                                    {role.name}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </FormItem>
                                <FormItem {...layout} label="Email" name="email" rules={[RequiredRule]}>
                                    <Input />
                                </FormItem>
                                <FormItem {...layout} label="Phone Number" name="phone" rules={[RequiredRule]}>
                                    <PhoneInput initialValue={user?.phone} onChange={() => { return; }} />
                                </FormItem>
                                <FormItem {...layout} label="Address Line 1" name="addressLine1" rules={[RequiredRule]}>
                                    <Input />
                                </FormItem>
                                <FormItem {...layout} label="Address Line 2" name="addressLine2">
                                    <Input />
                                </FormItem>
                                <FormItem {...layout} label="City" name="city" rules={[RequiredRule]}>
                                    <Input />
                                </FormItem>
                                <FormItem {...layout} label="State" name="state" rules={[RequiredRule]}>
                                    <StatePicker initialValue={user.state}  onChange={(val) => this.setState({state: val})}/>
                                </FormItem>
                                <FormItem  {...layout} label="Zipcode" name="zip" rules={[RequiredRule]}>
                                    <InputNumber />
                                </FormItem>
                                <FormItem>
                                    <Button type="primary"
                                        htmlType="submit"
                                        style={{ width: '100%' }}
                                        size="large">
                                        Save
                                </Button>
                                </FormItem>
                            </Form>
                        }
                    </Card>
                </Col>
            </Row>
        );
    }
    private saveChanges = (values: any) => {
        const { user } = this.state;
        let growers = values.growers.map((growerID: number) => { return { growerID: growerID } as GrowerDTO; });
        const roles = values.roles.map((roleId: number) => {return {id: roleId}});
        UserApiService.updateUser({
            externalLogins: null,
            username: values.username,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            city: values.city,
            state: values.state,
            zip: values.zip,
            id: user!.id,
            isActive: values.isActive,
            growers: growers,
            roles: roles,
        }).then((userDetail) => {
            const newUserRoles = userDetail.roles?.map(role => role.id) ?? [];
            this.setState({
                user: userDetail,
                userRoles: newUserRoles,
                edit: false
            });
            console.log("updated user: ", userDetail);
            notification.success({
                message: 'Changes Saved Succesfully'
            });
        }).catch(error => {
            notification.error({
                message: error.message,
                description: error.description
            });
        });

    }

    private resetPassword = () => {
        const email = this.state.user?.email;
        if (email) {
            let model = { email: email, url: null, password: null };
            AccountApiService.requestResetPassword(model).then(
                (ret) => {
                    console.log(ret);
                    this.setState({ resetSuccess: true, error: null });
                },
                (error) => {
                    console.log(error);
                    this.setState({ resetSuccess: false, error: error });
                }
            );
        }
    }
}

export default UserDetail;
