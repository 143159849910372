import { Alert, Button, Card, Col, Flex, Form, Input, Popconfirm, Row, Typography } from "antd"
import React, { useCallback, useMemo, useState } from "react"
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { useBinInfoContext } from "src/queries/BinInfoContext";
import { GetShivversDrySettings } from "./SettingsTable";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiError } from "src/api/ApiResultHandler";

export const useErrorLabel = () => {

    const [errorText, setErrorText] = useState<string | null>(null);

    const handleApiError = useCallback((error: unknown) => {
        const isApiError = error instanceof ApiError;
        console.log("handled error", error);
        if (isApiError) {
            setErrorText((error?.errorDetails)?.detail || error?.message);
        }
        else {
            setErrorText((error as Error)?.message);
        }
    }, [setErrorText]);

    const clearError = useCallback(() => {
        setErrorText(null);
    }, []);

    return useMemo(() => {
        return {
            errorText: errorText,
            clearError: clearError,
            handleApiError: handleApiError,
        };

    }, [errorText, clearError, handleApiError]);
}

export const SettingsEdit = (props: {}) => {

    const [form] = Form.useForm();
    const settingsEditFormId = "settingsEditForm";
    const binInfo = useBinInfoContext();

    const query = useQuery(
        {
            queryKey: binDBKeys.shivversSettingsHistory(binInfo.linkIpAddress!),
            queryFn: async (q) => GetShivversDrySettings(q.queryKey[1] as string, q.signal),
        });

        const errorLabelApi = useErrorLabel();

    const stopDryerMutation = useMutation({
        retry: 0,
        mutationFn: async () => {
            throw new Error("Remote stop dryer disabled in production");
        },
        onSuccess(data, variables, context) {
            errorLabelApi.clearError();
        },
        onError(error, variables, context) {
            errorLabelApi.handleApiError(error);
        },
    })

    
    const updateSettingsMutation = useMutation({
        retry: 0,
        mutationFn: async () => {
            throw new Error("Remote settings update disabled in production");
        },
        onSuccess(data, variables, context) {
            errorLabelApi.clearError();
        },
        onError(error, variables, context) {
            errorLabelApi.handleApiError(error);
        },
    })

    return <Card title="Adjust Settings" extra={<Button type="primary">Refresh Settings</Button>}>

        <Form form={form} id={settingsEditFormId} layout="vertical" onFinish={updateSettingsMutation.mutate}>
            <Row gutter={[16, 16]} justify={"space-between"}>
                <Col xs={8}>
                    <Flex wrap justify="center" vertical>
                        <Form.Item label="Transfer Moisture">
                            <Input />
                        </Form.Item>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Typography.Text strong>{query.data?.[0]?.transfer_Mst}</Typography.Text>
                        </div>
                    </Flex>
                </Col>
                <Col>
                    <Form.Item>
                        <Button type='primary' htmlType="submit" form={settingsEditFormId}>Submit</Button>
                    </Form.Item>
                </Col>
                <Col xs={8}>
                    <Flex wrap justify="center" vertical>
                        <Form.Item label="Max Temp">
                            <Input />
                        </Form.Item>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Typography.Text strong>{query.data?.[0]?.max_Temp}</Typography.Text>
                        </div>
                    </Flex>
                </Col>
            </Row>
        </Form>

        <Row justify={'center'}>
            <Col>
                <Popconfirm title="Are you sure you want to stop the dryer?" okText="Yes" cancelText="No" onConfirm={() => stopDryerMutation.mutate()}>
                    <Button type='primary' danger>Stop Dryer</Button>
                </Popconfirm>
            </Col>
        </Row>

        {errorLabelApi.errorText != null && <Row style={{paddingTop: 16}} justify={'center'} wrap>
            <Col>
                <Alert onClose={() => errorLabelApi.clearError()} closable type="error" description={<span style={{whiteSpace: 'pre-wrap'}}>{errorLabelApi.errorText}</span>} />
            </Col>
        </Row>}
    </Card>
}