import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Select, Form } from 'antd';
import BoardGroup from 'src/consts/BoardGroup';
import PCBLookupDTO from 'src/models/PCBLookupDTO';
import DeviceProfileDTO from 'src/models/DeviceProfileDTO';
import { getEnumDictionary } from 'src/utils/enumUtils';

const { Option } = Select;

export interface DeviceTableProps {
    deviceProfiles: DeviceProfileDTO[];
    pcbLookups: PCBLookupDTO[];
    onUpdateDevices: (binAssembly: DeviceProfileDTO[]) => void;
}

export const DeviceProfileTable = ({ onUpdateDevices, deviceProfiles, pcbLookups }: DeviceTableProps) => {
    const [addEntryModalVisible, setAddEntryModalVisible] = useState(false);
    const [form] = Form.useForm();
    const values = Form.useWatch([], {form, preserve: true});

    const [boardCounts, setBoardCounts] = useState(() => {
        const counts: Record<BoardGroup, number> = Object.values(BoardGroup).reduce((acc, group: BoardGroup) => {
            acc[group] = deviceProfiles.filter(b => b.boardLookupDTO?.boardGroupId === group).length;
            return acc;
        }, {} as Record<BoardGroup, number>);
        return counts;
    });

    const [sortedDevProfiles, setSortedDevProfiles] = useState<DeviceProfileDTO[]>([]);

    useEffect(() => {
        const updatedProfiles = [...deviceProfiles].sort((a, b) => a.canID - b.canID);
        const counts: Record<BoardGroup, number> = Object.values(BoardGroup).reduce((acc, group: BoardGroup) => {
            acc[group] = updatedProfiles.filter(b => b.boardLookupDTO?.boardGroupId === group).length;
            return acc;
        }, {} as Record<BoardGroup, number>);
    
        const updatedDeviceProfiles = updateDeviceProfiles(updatedProfiles, counts);
    
        setSortedDevProfiles(updatedDeviceProfiles);
        setBoardCounts(counts);
    }, [deviceProfiles]);
    

    const updateDeviceProfiles = (profiles: DeviceProfileDTO[], counts: Record<BoardGroup, number>): DeviceProfileDTO[] => {
        Object.values(BoardGroup).forEach(group => {
            let i = 1;
            profiles
                .filter(b => b.boardLookupDTO?.boardGroupId === group)
                .forEach(b => {
                    b.canID = b.boardLookupDTO?.baseCanID! + i;
                    b.boardNumber = i;
                    b.name = `${b.boardLookupDTO?.initials}${i}`;
                    i++;
                });
        });
    
        return profiles;
    };
    

    const boardTypes = getEnumDictionary(BoardGroup).map(({ label, value }) => (
        <Select.Option key={value} value={value}>
            {label}
        </Select.Option>
    ));

    const handleDelete = (record: DeviceProfileDTO) => {
        const updatedProfiles = deviceProfiles.filter(profile => profile !== record);
        const group = record.boardLookupDTO?.boardGroupId;
        if (group !== undefined) {
            setBoardCounts(prev => ({ ...prev, [group]: prev[group] - 1 }));
        }
        onUpdateDevices(updatedProfiles);
    };

    const onFinish = (values: any) => {
        const lookup = pcbLookups.find(b => b.name === values.boardLookup);
        if (!lookup) return;

        const group : BoardGroup = values.boardType;
        const newBoardId = lookup.baseCanID + boardCounts[group] + 1;
        const newDeviceProfile: DeviceProfileDTO = {
            name: `${lookup.initials}${boardCounts[group] + 1}`,
            canID: newBoardId,
            boardNumber: (newBoardId & 0x00F),
            canIDStr: `0x${newBoardId.toString(16)}`,
            sensors: [],
            actuators: [],
            boardLookupDTO: lookup,
        };

        setBoardCounts(prev => ({ ...prev, [group]: prev[group] + 1 }));
        onUpdateDevices([...deviceProfiles, newDeviceProfile]);
        form.resetFields();
        setAddEntryModalVisible(false);
    };

    return (
        <>
            <Table
                dataSource={sortedDevProfiles}
                columns={[
                    { title: 'Name', dataIndex: 'name', key: 'name' },
                    { title: 'CAN ID', dataIndex: 'canID', key: 'canID', render: (text) => `0x${text.toString(16)}` },
                    { title: 'Lookup', dataIndex: 'boardLookupDTO', key: 'boardLookupDTO', render: (text) => text?.name },
                    {
                        title: 'Actions',
                        key: 'actions',
                        render: (record) => <Button onClick={() => handleDelete(record)}>Delete</Button>,
                    },
                ]}
            />
            <Button onClick={() => setAddEntryModalVisible(true)}>Add New Entry</Button>
            <Modal
                title="Add New Entry"
                open={addEntryModalVisible}
                okButtonProps={{ style: { display: 'none' } }}
                onCancel={() => setAddEntryModalVisible(false)}
            >
                <Form form={form} onFinish={onFinish} autoComplete="off">
                    <Form.Item
                        label="Select Board Type"
                        name="boardType"
                        rules={[{ required: true }]}
                        initialValue={BoardGroup.Hub}
                    >
                        <Select>
                            {boardTypes}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Select Board Lookup"
                        name="boardLookup"
                        rules={[{ required: true }]}
                    >
                        <Select
                            showSearch
                            options={pcbLookups
                                .filter(pcb => pcb.boardGroupId === values?.boardType)
                                .map(pcb => ({ label: pcb.name, value: pcb.name }))}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
