import { useQuery } from "@tanstack/react-query";
import ShivversService from "src/api/ShivversService";
import { ExtendedColumnType, useColumnExpansion } from "../binOverview/BinCommander";
import AdminUserSearchDTO from "src/models/AdminUserSearchDTO";
import { Col, Input, Row, Select, Space, Table, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useLocale } from "antd/es/locale";
import Routes from "src/consts/Routes";
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE_TIME_MS } from "./UserList";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import RoleUtil from "src/utils/RoleUtil";


export const PremierUserList = () => {
    const [nameSearch, setNameSearch] = useState("");
    const nameSearchDelayedValue = useDebouncedCallback((value) => {
        setNameSearch(value);
    }, DEBOUNCE_TIME_MS);

    const [securityFilter, setSecurityFilter] = useState<number[]>([]);

    const query = useQuery({
        queryKey: [{purpose: "userSearch", scope: "userEdit"}] as const,
        queryFn: async (q) => {
            return await ShivversService.searchUsers(undefined, q.signal);
        }
    });
    const history = useHistory();
    const location = useLocation();

    const columns: Array<ExtendedColumnType<AdminUserSearchDTO>> = useMemo(() => [
        {
            dataIndex: "internalId",
            hidden: true,
        },
        {
            dataIndex: "externalId",
            hidden: true,
        },
        {
            dataIndex: "security",
            title: "Security",
            hidden: true,
        },
        {
            title: "Account",
            dataIndex: "account",
            sortType: 'string',
        },
        {
            title: "Last_Name",
            dataIndex: "lastName",
            defaultSortOrder: "ascend",
            sortType: 'string',
            filterSearch: true,
            render(value, record, index) {
                if (record.security !== 1) {
                    return <span>{value}</span>
                }
                return <div>
                    <Typography.Link href={`${Routes.BIN_STATS_SHIVVERS}/${record['externalId']}`} target="_blank">{value}</Typography.Link>
                </div>
            },
        },
        {
            title: "First_Name",
            dataIndex: "firstName",
            sortType: 'string',
        },
        {
            title: "Address",
            dataIndex: "address",
            sortType: 'string',
        },
        {
            title: "City",
            dataIndex: "city",
            sortType: 'string',
        },
        {
            title: 'State',
            dataIndex: 'state',
            sortType: 'string',
        },
        {
            title: 'Dealer',
            dataIndex: 'dealer',
            sortType: 'string',
        },
        {
            title: "DSM",
            dataIndex: "regionId",
            sortType: "string",
            render(value, record, index) {
                if (value === 1100) {
                    return "1100 Glen Muench";
                }
                else if (value === 1700) {
                    return "1700 Mark Sexton";
                }
                else if (value === 2100) {
                    return "2100 Kyle Canady";
                }
                else if (value === 2900) {
                    return "2900 Scott Graham";
                }
                else if (value === 3000) {
                    return "3000 Tom Barron";
                }
                else if (value === 3300) {
                    return "3300 BJ Arbuckle";
                }
                else {
                    return value;
                }
            },
        },
        {
            title: 'Premier',
            dataIndex: 'premier',
            sortType: 'string',
        },
        {
            title: 'Activation_Date',
            dataIndex: 'activationDateIso8601',
            render: (value, record, index) => {
                if (!value) {
                    return value;
                }
                const parsed = dayjs(value);
                if (!parsed.isValid()) {
                    return value;
                }
                return dayjs(value).tz("America/Chicago", false)?.format('MMM D YYYY');
            },
            sorter: (a, b, sortOrder) => {
                // put nulls at end of sorts
                // https://github.com/TanStack/table/discussions/2371#discussioncomment-210260
                if (!a.activationDateIso8601 && !b.activationDateIso8601) {
                    return 0;
                }

                if (!a.activationDateIso8601) {
                    return sortOrder === "descend" ? -1 : 1;
                }

                if (!b.activationDateIso8601) {
                    return sortOrder === "descend" ? 1 : -1;
                }
                return dayjs(a.activationDateIso8601).diff(b.activationDateIso8601);
            }
        },
        {
            title: "Details",
            hidden: !RoleUtil.currentUserIsAdmin(),
            render(value, record, index) {
                return <NavLink to={Routes.generate(Routes.USERS_DETAIL, { id: record.internalId })}>View / Edit <EditOutlined /></NavLink>
            },
        },

    ], [query.data]);






    let transformed = useMemo(() => useColumnExpansion({ columns: columns, datasource: query.data ?? [] }), [query.data, columns]);
    const updateFilters = () => {
        if (nameSearch === "" && securityFilter.length === 0) {
            return query.data ?? [];
        }

        const filteredTable = query.data
            ?.filter((record) => {
                if (nameSearch == "") {
                    return true;
                }

                return record.lastName?.toUpperCase()?.startsWith(nameSearch.toUpperCase())
            || record.firstName?.toUpperCase()?.startsWith(nameSearch.toUpperCase())
            || record.account === (nameSearch)}
        )?.filter((record) => {
            if (securityFilter.length === 0) {
                return true;
            }
            return securityFilter.includes(record.security!);
        }
         )
            ?? [];
        return filteredTable;
    };

    const finalTable = useMemo(() => updateFilters(), [nameSearch, securityFilter, query.data]);

    return <>
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Space wrap direction="horizontal" size="middle">
                    <Input style={{ width: 240 }} onChange={(evt) => nameSearchDelayedValue(evt.target.value)} placeholder='Search by Name or Account'></Input>
                    <Input style={{ width: 240 }} onChange={(evt) => {
                        const parsed = Number.parseInt(evt.target.value);
                        if (!Number.isInteger(parsed)) {
                            setSecurityFilter([]);
                            return;
                        }
                        setSecurityFilter([parsed])}
                        } placeholder='Search by Security'></Input>
                </Space>
            </Col>
            <Col xs={24}>
                <Table onRow={(data, index) => {
                    return {
                        // onClick: (d) => {
                        //     if (data.security !== 1) {
                        //         return;
                        //     }
                        //     const binStatsRoute = `${Routes.BIN_STATS_SHIVVERS}/${data['internalId']}`;

                        //     history.push(binStatsRoute);
                        // }
                    }
                }}
                    size="small" rowKey="externalId" pagination={{ defaultPageSize: 200, size: "default" }} loading={query.isLoading} dataSource={finalTable ?? []} columns={transformed}

                />
            </Col>
        </Row>

    </>;
}