import * as React from 'react';
import History from 'src/utils/HistoryUtil';
import HistoryUtil from 'src/utils/HistoryUtil';
import Role from 'src/consts/Role';
import RoleUtil from 'src/utils/RoleUtil';
import Routes, { UserSource } from 'src/consts/Routes';
import Scrollbars from 'react-custom-scrollbars-2';
import UserSecurity from 'src/models/UserSecurity';
import { connect } from 'react-redux';
import {
    DesktopOutlined,
    GlobalOutlined,
    // FileTextOutlined,
    HomeOutlined,
    ReconciliationOutlined,
    TeamOutlined,
    ToolOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { StateStoreModel } from 'src/redux/state/StateStoreModel';
import { UserSessionActions } from 'src/redux/actions/UserSessionActions';
import './SideNav.less';
import { Location } from 'history';
interface SideNavProps {
    user: UserSecurity | null;
    logoutAction: () => void;
    collapsed: boolean;
}

interface SideNavState {
    current: string;
}

const MYSHIVVERS_URL = "http://myshivvers.com/link/frmuser.aspx";
const MYSHIVVERS_MENU_KEY = UserSource.MyShivvers;

const USERS_MENU_KEY = "users";
class SideNav extends React.Component<SideNavProps, SideNavState> {
    private homeUrl: string;
    private reportsUrl: string;
    private usersUrl: string;
    private adminUrl: string;
    private profileUrl: string;
    private binOverviewUrl: string;
    private usersInternalUrl: string;
    private usersMyShivverListUrl: string;
    private shivversUserAddUrl: string;

    constructor(props: SideNavProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.getSelectedNavItem = this.getSelectedNavItem.bind(this);

        this.homeUrl = Routes.generate(Routes.HOME_ROUTE);
        this.usersUrl = Routes.generate(Routes.USERS_BASE);
        this.reportsUrl = Routes.generate(Routes.REPORTS_BASE);
        this.adminUrl = Routes.generate(Routes.ADMIN);
        this.profileUrl = Routes.generate(Routes.PROFILE);
        this.binOverviewUrl = Routes.generate(Routes.BIN_OVERVIEW);
        this.shivversUserAddUrl = Routes.SHIVVERS_USER_ADD;

        this.state = { current: this.getSelectedNavItem(HistoryUtil.location) };

        History.listen((location, action) => {
            this.setState({ current: this.getSelectedNavItem(location) });
        });
    }

    handleClick = (e: any) => {
        if (e.key === MYSHIVVERS_MENU_KEY) {
            return;
        }

        this.setState({
            current: e.key,
        });
    }


    render() {

        let selectedKeys = [this.state.current];

        return (
            <React.Fragment>

                <Scrollbars autoHide={true} className="scrollbar-sidenav">
                    <Menu
                        onClick={this.handleClick}
                        defaultSelectedKeys={['home']}
                        selectedKeys={selectedKeys}
                        mode="inline"
                    >
                        <Menu.Item key="home" icon={<DesktopOutlined />}>
                            <NavLink to={this.homeUrl}>
                                <span>Dashboard</span>
                            </NavLink>
                        </Menu.Item>

                        {RoleUtil.CanViewBinFleet() ? (
                            <Menu.Item key="binOverview" icon={<ReconciliationOutlined />}>
                                <NavLink to={this.binOverviewUrl}>
                                    <span>Bin Fleet</span>
                                </NavLink>
                            </Menu.Item>
                        ) : undefined}
                        <Menu.Divider />

                        {RoleUtil.CanViewUserList() ? (
                            <Menu.Item  key={USERS_MENU_KEY} icon={<TeamOutlined />}>
                                <NavLink to={{
                                    pathname: this.usersUrl,
                                    search: new URLSearchParams({userSource: UserSource.MyShivvers}).toString(),
                                }}>
                                    {/* <span>MyShivvers Users</span> */}
                                    <span>Users</span>
                                </NavLink>
                            </Menu.Item>
                        ) : undefined}
                        <Menu.Divider />


                        {RoleUtil.currentUserIsAdmin() ? (
                            <Menu.Item key="admin" icon={<ToolOutlined />}>
                                <NavLink to={this.adminUrl}>
                                    <span>Admin</span>
                                </NavLink>
                            </Menu.Item>
                        ) : undefined}


                    </Menu>
                </Scrollbars>
            </React.Fragment>
        );
    }

    private getSelectedNavItem(location: Location<unknown>): string {
        const initialLocation = location.pathname;
        let selectedItem = '';

        if (initialLocation.indexOf(this.usersUrl) >= 0) {
            const params = new URLSearchParams(location.search);
            if(params.get("userSource") == UserSource.Internal) {
                return UserSource.Internal;
            }
            else if(params.get("userSource") == UserSource.MyShivvers) {
                return UserSource.MyShivvers;
            }
            else {
                selectedItem = USERS_MENU_KEY;
            }
        } else if (initialLocation.indexOf(this.shivversUserAddUrl) >= 0) {
            selectedItem = USERS_MENU_KEY;
        } else if (initialLocation.indexOf(this.reportsUrl) >= 0) {
            selectedItem = 'reports';
        } else if (initialLocation.indexOf(this.adminUrl) >= 0) {
            selectedItem = 'admin';
        } else if (initialLocation.indexOf(this.profileUrl) >= 0) {
            selectedItem = '';
        } else if (initialLocation.indexOf(this.binOverviewUrl) >= 0) {
            selectedItem = 'binOverview';
        } else if (initialLocation.indexOf(this.homeUrl) >= 0) {
            selectedItem = 'home';
        }
        return selectedItem;
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        logoutAction: () => dispatch(UserSessionActions.logout())
    };
}

function mapStateToProps(state: StateStoreModel) {
    return {
        user: state.UserSession.Value
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
