import Icon, { ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Row, Skeleton, Space, Badge } from "antd";
import Table, { ColumnType } from "antd/es/table";
import Column from "antd/es/table/Column";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BinApiService from "src/api/BinApiService";
import BinDTO from "src/models/BinDTO";
import StackDTO from "src/models/StackDTO";
import { useBinStateFromAzure } from "src/queries/useBinStateFromAzure";
import { OperatingMode } from "./BinVisualThree";
// @ts-ignore
import { useIntervalWhen } from 'rooks';
//@ts-ignore
import ExhaustIconSVG from 'src/statics/exhaust.svg';
//@ts-ignore
import ClosedIconSVG from 'src/statics/closed.svg';
//@ts-ignore
import VentIconSVG from 'src/statics/vent.svg';
//@ts-ignore
import FlowIconSVG from 'src/statics/flow.svg';
import ValveDTO from "src/models/ValveDTO";
import { useRequestNewBinStateMutation } from "src/pages/binOverview/BinCommander";
import ValvePositionDTO from "src/models/ValvePositionDTO";
import { useTurnOnCompressorMutation } from "./CompressorControls";
import { CheckFailedIcon, CheckPassedIcon } from "src/pages/features/WeatherMonitorAntdOnly";

export function ClosedIcon(props: any) {
    const style = props.style ?? { width: "100%", height: "100%" };
    const { width, height, ...restStyle } = style;
    return (
        <img src={ClosedIconSVG} alt="Closed" width={width} height={height} style={{ ...restStyle }} />
    );
}

export function FlowIcon(props: any) {
    const style = props.style ?? { width: "100%", height: "100%" };
    const { width, height, ...restStyle } = style;
    return (
        <img src={FlowIconSVG} alt="Flow" width={width} height={height} style={{ ...restStyle }} />
    );
}

export function VentIcon(props: any) {
    const style = props.style ?? { width: "100%", height: "100%" };
    const { width, height, ...restStyle } = style;
    return (
        <img src={VentIconSVG} alt="Vent" width={width} height={height} style={{ ...restStyle }} />
    );
}

export function ExhaustIcon(props: any) {
    const style = props.style ?? { width: "100%", height: "100%" };
    const { width, height, ...restStyle } = style;
    return (
        <img src={ExhaustIconSVG} alt="Exhaust" width={width} height={height} style={{ ...restStyle }} />
    );
}


enum ValveState {
    CLOSED = "Closed",
    FLOW = "Flow",
    VENT = "Vent",
    EXHAUST = "Exhaust",
}
export interface NewValveDisplayProps {
    binDTO: BinDTO;
    binId: number;
    azureDeviceId: string;
    allowEdits: boolean;
    valveDisplayInstance: ValveDisplayInstance
}

const COMMON_CLOSE_DEFAULT_PERIOD_SECONDS = 15;

const computeValvesHashFromStacksData = (stacksData: StackDTO[]): string => {
    const valvesHash = stacksData.flatMap(stacks => stacks.valves).map(valve => valve?.id).join(",");
    return valvesHash;

}

const calcExtraNeeded = (len: number, pageLimit: number) => {
    if (len <= pageLimit) {
        return pageLimit - len;
    }
    const remainder = len % pageLimit;
    return (pageLimit - remainder) % pageLimit;
}

function stacksToLayer(stacksArr: StackDTO[]) {
    const stacksData = stacksArr;
    const numStacks = stacksData?.length ?? 0;
    if (numStacks === 0) {
        return [];
    }

    const highestLayer = Math.max(...stacksData?.map(stack => stack?.valves?.length ?? 0));

    // @ts-ignore
    const layers: Partial<StackDTO & { layerId: number, valves: Array<ValveDTO & { stackId: string }> }>[] = Array.from({ length: highestLayer },
        () => ({ valves: Array.from({ length: numStacks }).fill({ Id: null }) }));

    stacksData.forEach((stack, stackIndex) => {
        stack.valves!.forEach((layer, layerIndex) => {
            layers[layerIndex].layerId = layer.number;
            layers[layerIndex!].valves![stackIndex] = { ...layer, stackId: stack.id! };

        })
    }
    );
    return layers;
}

const positionToText = (position: ValveState) => {

    if (position === ValveState.CLOSED) {
        return "Close";
    }
    else if (position === ValveState.EXHAUST) {
        return "Exhau";
    }

    else if (position === ValveState.FLOW) {
        return "Flow";
    }
    else if (position === ValveState.VENT) {
        return "Vent";
    }
    else {
        return "-";
    }
};

const getIcon = ( position: ValveState) => {
    return <span>{positionToText(position)}</span>
}


const flattenValveStates = (stacksData: StackDTO[]) => {
    if (stacksData?.length == null || stacksData == null) {
        return {};
    }
    if (stacksData?.length === 0) {
        return {};
    }

    const valves: Record<string, ValveDTO> = {};
    stacksData.forEach(stack => {
        stack.valves?.forEach(valve => {
            valves[valve.id!] = valve;
        })
    });
    return valves;

}

interface ValveDisplayInstance {
    hasValvesToSubmit: boolean;
    desiredValveStates: Record<string, { position: ValveState}>;
    clearAllDesiredValves: (options?: {clearSelections: boolean, clearFailedValves: boolean}) => void;
    clearAllSelectedValves: () => void;
    setDesiredValveStates: React.Dispatch<React.SetStateAction<Record<string, {
        position: ValveState;
    }>>>;
    selectedValves: Record<string, ValveDTO>;
    setSelectedValves: React.Dispatch<React.SetStateAction<Record<string, ValveDTO>>>;
    failedValves: Record<string, any>;
    setFailedValves: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    checkForNewStackData: boolean
    setCheckForNewStackData: React.Dispatch<React.SetStateAction<boolean>>;
    isProcessingStackData: boolean;
    setIsProcessingStackData: React.Dispatch<React.SetStateAction<boolean>>;
    disableSubmission: boolean;
}
export const useValveDisplay = (): ValveDisplayInstance => {

    const [desiredValveStates, setDesiredValveStates] = useState<Record<string, { position: ValveState }>>({});
    const hasValvesToSubmit = useMemo(() => Object.keys(desiredValveStates).length > 0, [desiredValveStates]);
    const [selectedValves, setSelectedValves] = useState<Record<string, ValveDTO>>({});
    const [failedValves, setFailedValves] = useState<Record<string, any>>({});

    const [checkForNewStackData, setCheckForNewStackData] = useState(false);
    const [isProcessingStackData, setIsProcessingStackData] = useState(false);

    const disableSubmission = checkForNewStackData || isProcessingStackData;

    const clearAllSelectedValves = useCallback(() => {
        setSelectedValves({});
    }, [setDesiredValveStates]);

    const clearAllDesiredValves = useCallback((options?: {clearSelections?: boolean, clearFailedValves?: boolean}) => {
        setDesiredValveStates({});
        if (options?.clearSelections) {
            clearAllSelectedValves();
        }
        if (options?.clearFailedValves) {
            setFailedValves({});
        }
    }, [setDesiredValveStates, clearAllSelectedValves, setFailedValves]);




    return useMemo(() => {
        return {
            desiredValveStates, setDesiredValveStates, hasValvesToSubmit, clearAllDesiredValves, clearAllSelectedValves, selectedValves, setSelectedValves,
            failedValves, setFailedValves,
            disableSubmission, checkForNewStackData, setCheckForNewStackData, isProcessingStackData, setIsProcessingStackData,
        };
    }, [desiredValveStates, setDesiredValveStates, hasValvesToSubmit, clearAllDesiredValves, clearAllSelectedValves, selectedValves, setSelectedValves, failedValves, setFailedValves,
        disableSubmission, checkForNewStackData, setCheckForNewStackData, isProcessingStackData, setIsProcessingStackData,
    ]);
}

interface GrainCoveredDotProps{
    covered: boolean,
    inIcon: boolean
}
export const GrainCoveredDot = (props:GrainCoveredDotProps) => {
    return <div style={{
        zIndex: 20,
        //backgroundColor: props.covered ? "green":"red",
        //borderRadius: "50%",
        width: "6px",
        height: "6px",

        position: "relative",
        top: props.inIcon ? -32 : 2,
        right: props.inIcon ? -41 : 4,
    }}>
        {props.covered ? <CheckPassedIcon /> : <CheckFailedIcon />}
    </div>
}

export const NewValveDisplay = (props: NewValveDisplayProps) => {

    const allStackData = props.binDTO?.stacks;
    const stacksData = allStackData ?? [];
    const layers = stacksToLayer(stacksData);
    const {desiredValveStates, hasValvesToSubmit, setDesiredValveStates, selectedValves, setSelectedValves, failedValves, setFailedValves,
        checkForNewStackData, disableSubmission, isProcessingStackData, setCheckForNewStackData, setIsProcessingStackData
    } = props.valveDisplayInstance ?? useValveDisplay();
    const numLayers = layers?.length ?? 0;
    const numStacks = stacksData?.length ?? 0;
    const [submittedConfigHash, setSubmittedValvesHash] = useState<string | null>(null);
    const priorValveStates = useMemo(() => flattenValveStates(stacksData), [stacksData]);
    const [stackPage, setStackPage] = useState(0);
    const [layerPage, setLayerPage] = useState(0);
    const stacksPerPage = 6;

    const endStackPage = Math.max(Math.ceil(numStacks / stacksPerPage) - 1, 0);

    const layersPerPage = 3;
    const endLayerPage = Math.max(Math.ceil(numLayers / layersPerPage) - 1, 0);

    const [staleCaptureTime, setStaleCaptureTime] = useState<number | null>(null);
    const [submittedValveStates, setSubmittedValveStates] = useState<Record<string, ValvePositionDTO> | null>(null);



    const DEADLINE_STACK_SHOULD_UPDATE_IN_MS = (15 + (props.binDTO?.desiredProperties?.overrides?.commonCloseTimePeriod ?? COMMON_CLOSE_DEFAULT_PERIOD_SECONDS)) * 1000;

    const allowChangingStacks = props.allowEdits;
    const requestNewBinStateMutation = useRequestNewBinStateMutation(props.azureDeviceId);
    const turnOnCompressorMutation = useTurnOnCompressorMutation(props.azureDeviceId);

    useIntervalWhen(
        async () => {

            try {
                await requestNewBinStateMutation.mutateAsync();
            } catch (err) {
                console.log("error requesting upload of new binState", err);
            }
            // check stack data
            const currentDateTime = new Date();
            const currentTimestamp = currentDateTime.getTime();
            const captureTimeString = currentDateTime.toISOString();
            console.debug(`new stack data: ${captureTimeString}`, allStackData?.[0].valves?.[0].positionLabel);
            const timeWaitingForData = currentTimestamp - staleCaptureTime!;
            console.debug(`time elapsed: ${timeWaitingForData}, out of ${DEADLINE_STACK_SHOULD_UPDATE_IN_MS}`);
            const deadlineExceeded = timeWaitingForData > DEADLINE_STACK_SHOULD_UPDATE_IN_MS;

            const newValvesHash = computeValvesHashFromStacksData(stacksData);

            // if the stack layout changed after submitting, ask to resubmit a new config and verify existing results
            const valveConfigChanged = submittedConfigHash !== newValvesHash;
            if (valveConfigChanged) {
                console.debug(`stack layout changed., oldHash=${submittedConfigHash} newHash=${newValvesHash}`);
                // invalidate config hash submitted
                setSubmittedValvesHash(null);
                setFailedValves({});
                setCheckForNewStackData(false);
                message.warning("Stack layout has changed since submission. Verify current valve positions and resubmit");
                return;
            }

            const failedValvesResult = {};

            for (const stack of stacksData) {
                for (const valve of stack.valves ?? []) {
                    const submittedValve = submittedValveStates?.[valve.id!];
                    if (submittedValve == null) {
                        failedValvesResult[valve.id!] = { valveId: valve.id, position: null };
                        continue;
                    }

                    const desiredPosition = submittedValve.position;
                    const newPosition = valve.positionLabel;

                    const valveUpdateSucceeded = newPosition === desiredPosition;

                    if (!valveUpdateSucceeded) {
                        failedValvesResult[valve.id!] = {
                            stackId: stack.id, layerId: valve.number, valveId: valve.id,
                            position: submittedValve.position
                        };
                    }
                }
            }

            // todo: Show ignored solendoids
            // try {
            //     // remove failed valves due to being forced in a position by ignoreSolenoids
            //     for (const ignoreSolenoid of allStackData?.IgnoredSolenoids ?? []) {
            //         // get only the valveId portion
            //         const ignoreId: string = ignoreSolenoid.SolenoidId as string;
            //         const ignoredValveId = ignoreId.slice(0, 2);
            //         delete failedValvesResult[ignoredValveId];
            //         console.log(`Ignoring ignored valveId: ${ignoredValveId}`);
            //     }
            // } catch (err) {
            //     console.error("problem processing ignored valves", err);
            //     message.error("problem processing ignored valves, this shouldn't happen");
            // };

            const allPass = Object.keys(failedValvesResult).length === 0;

            // todo: Ignore valve selection
            // let ignoredValves: Set<string> = new Set();
            // try {
            //     ignoredValves = new Set(allStackData?.IgnoredSolenoids?.map(sol => sol?.SolenoidId?.slice(0, 2)));
            // } catch (err) {
            //     console.log(err);
            //     message.error("problem processing ignored valves, this shouldn't happen");
            // }

            let ignoreMsg = '';
            // if (ignoredValves.size > 0) {
            //     ignoreMsg = ` - ignored valves: ${[...ignoredValves].join(', ')}`
            // }

            if (allPass) {
                console.debug(`success: all valves updated. Took ${timeWaitingForData}ms`);
                message.info(`Valves updated${ignoreMsg}`);
                setCheckForNewStackData(false);
                setSubmittedValvesHash(null);
                setFailedValves({});
                return;
            }

            if (!deadlineExceeded && !allPass) {
                console.debug("waiting for valves... : ", failedValvesResult);
            }

            if (deadlineExceeded && !allPass) {
                message.warning("SetValvePositions: Not all valves updated");
                console.warn("valve update deadline exceeded: failed to update these valves: ", failedValvesResult);
                setCheckForNewStackData(false);
                setSubmittedValvesHash(null);
                setFailedValves(failedValvesResult);
            }

        },
        3000, // run callback every 3 second
        checkForNewStackData, // start the timer when it's true
    );



    useIntervalWhen(() => {

        const numFailingValves = Object.keys(failedValves).length;
        if (numFailingValves === 0) {
            return;
        }

        const valvesThatStillFail = {};
        for (const stack of allStackData ?? []) {
            for (const valve of stack.valves ?? []) {
                const priorFailingValve = failedValves[valve.id!];
                if (priorFailingValve == null) {
                    continue;
                }

                if (valve.positionLabel === priorFailingValve.position) {
                    continue
                }

                valvesThatStillFail[valve.id!] = priorFailingValve;
            }



        }

        setFailedValves(valvesThatStillFail);

    }, 3000, Object.keys(failedValves).length > 0);
    console.log("the stacks data", stacksData);

    if (stacksData?.length <= 0) {
        <Skeleton active />
    }


    const lookupPositionToShow = (valveId: string, originalPosition: ValveState): ValveState => {
        const maybeChanged = desiredValveStates[valveId];
        if (maybeChanged == null) {
            return originalPosition;
        }
        const desiredPosition = maybeChanged.position;
        return desiredPosition;
    }

    const valveFailed = (valveId: string, currentPosition: ValveState): boolean => {
        const maybeFailed = failedValves[valveId];
        if (maybeFailed == null) {
            return false;
        }
        else {
            return true;
        }
    }

    const handleValveClick = (valve: ValveDTO) => {
        const valveId = valve?.id;
        // Empty cell
        if (valveId == null) {
            return;
        }

        const alreadySelected = selectedValves.hasOwnProperty(valveId);
        if (alreadySelected) {
            // remove from selected
            const { [valveId]: excluded, ...rest } = selectedValves;
            setSelectedValves({ ...rest });
            console.debug(`Deselected valve: ${valveId}`);
        }
        else {
            setSelectedValves({ ...selectedValves, [valveId]: valve })
            console.debug(`set selected valve: ${valveId}`);
        }
    }

    const handleStackSelect = (event: any, stackId: string) => {
        const stack = stacksData.find(stack => stack.id === stackId);
        if (stack == null) {
            return;
        }
        const valves = [...stack?.valves ?? []];
        const newValves = {};
        for (const valve of valves) {
            newValves[valve.id!] = valve;
        }
        const valveIds = valves.map(valve => valve.id!);

        const foundExistingSelection = Object.keys(selectedValves).find(valveId => valveId.startsWith(stackId));
        console.debug(`requested to ${foundExistingSelection ? "Deselect" : "Select"} all of stack ${stackId}`);

        if (foundExistingSelection) {
            // deSelect all valves from this stack
            const newSelectedValves = { ...selectedValves };
            for (const valveId of valveIds) {
                delete newSelectedValves[valveId];
            }

            setSelectedValves(newSelectedValves);
        }
        else {
            // add all valves
            setSelectedValves({ ...selectedValves, ...newValves });
        }

    }

    const handleQueueActions = (position: ValveState) => {

        console.log(`queue all selected valves to ${position}`);

        const changes = {};
        Object.keys(selectedValves)
            .forEach(valveId => {
                changes[valveId] = { position: position };
            }
            );

        setDesiredValveStates({ ...desiredValveStates, ...changes });
        setSelectedValves({});
    }

    const handleLayerSelect = (layerId: number) => {
        const layerAlreadySelected = Object.values(selectedValves).find(valve => valve.number === layerId);

        console.debug(`requested to ${layerAlreadySelected ? "DeSelected" : "Select"} all of layer ${layerId}`);
        const layer = layers.find(layer => layer.layerId === layerId);

        if (layer == null) {
            return;
        }

        const valves = layer.valves;

        const newValves = {};
        for (const valve of valves ?? []) {
            if (valve.id == null) {
                continue;
            }
            newValves[valve.id!] = valve;
        }

        const valveIds = valves?.map(valve => valve.id).filter(id => id != null) ?? [];

        if (!layerAlreadySelected) {
            // add all valves
            setSelectedValves({ ...selectedValves, ...newValves });
        }
        else {
            // deSelect all valves from this layer
            const newSelectedValves = { ...selectedValves };
            for (const valveId of valveIds) {
                delete newSelectedValves[valveId!];
            }

            setSelectedValves(newSelectedValves);
        }
    }

    const handleStackIncrement = () => {
        if (stackPage >= endStackPage) {
            return;
        }

        setStackPage(page => page + 1);
    }


    const handleStackDecrement = () => {
        if (stackPage <= 0) {
            return;
        }

        setStackPage(page => page - 1);
    }

    const handleLayerIncrement = () => {
        if (layerPage >= endLayerPage) {
            return;
        }

        setLayerPage(page => page + 1);
    }

    const handleLayerDecrement = () => {
        if (layerPage <= 0) {
            return;
        }

        setLayerPage(page => page - 1);
    }

    const submitNewValvesState = async () => {
        // turn on compressor

        let compressorRequestSucceeded = false;
        try {
            const result = await turnOnCompressorMutation.mutateAsync();
            if (result.success === true) {
                compressorRequestSucceeded = true;
            }
            else {
                message.error("Problem turning on compressor");
            }
        } catch (error) {
            console.log("error turning on compressor", error);
            message.error(`Error turning on compressor`);
        }
        if (!compressorRequestSucceeded) {
            return;
        }

        let merged: Record<string, { valveId: string, position: string }> = {};

        // use prior valve states as defaults to send
        for (const [valveId, valve] of Object.entries(priorValveStates)) {
            merged[valveId] = { valveId: valveId, position: valve?.positionLabel! };
        }

        // override with desired changes
        for (const [valveId, valve] of Object.entries(desiredValveStates)) {
            merged[valveId] = { valveId: valveId, position: valve.position };
        }

        const valvePositions = Object.values(merged);

        const valvesHash = computeValvesHashFromStacksData(stacksData);
        console.debug(`setting config hash submission to, configHash=${valvesHash}`);
        setSubmittedValvesHash(valvesHash);
        setSubmittedValveStates(merged);


        let errorMsg: string | null | object = null;

        const lastCaptureDate = new Date();
        const captureTimeString = lastCaptureDate.toISOString();

        console.debug(`submitting new States @ ${captureTimeString}`);
        setIsProcessingStackData(true);
        setDesiredValveStates({});
        setSelectedValves({});


        try {
            const result = await BinApiService.setValvePositions(props.azureDeviceId, valvePositions);
            setIsProcessingStackData(false);
            if (result === true) {
                const statesSubmittedAt = new Date();
                setStaleCaptureTime(statesSubmittedAt.getTime());
                setCheckForNewStackData(true);
                console.debug(`submitted new States @ ${statesSubmittedAt.toISOString()}`);
            } else {

            }

        }
        catch (err) {
            console.log(err);
            errorMsg = 'Failed to send valves change request';
            setIsProcessingStackData(false);
        }
        if (errorMsg) {
            message.error(errorMsg);
        }
    }

    const failedLayerPageDirections = (): { before: boolean, current: boolean, after: boolean } => {
        const failedLayerIds = Object.values(failedValves).map(valve => valve.layerId);
        if (failedLayerIds.length === 0) {
            return { before: false, current: false, after: false };
        }

        let presentBefore = false;
        let presentCurrent = false;
        let presentAfter = false;


        let priorLayerPageheaders = new Set();
        if (layerPage === 0) {
            priorLayerPageheaders = new Set();
        }
        else {
            const _priorLayerSlice = layerHeaders;
            priorLayerPageheaders = new Set(_priorLayerSlice);
        }
        const currentLayerPageHeaders = new Set(layerHeaders);

        const nextLayerPageheaders = new Set(layerHeaders);

        for (const valveLayer of failedLayerIds) {
            if (priorLayerPageheaders.has(valveLayer)) {
                presentBefore = true;
            }
            if (currentLayerPageHeaders.has(valveLayer)) {
                presentCurrent = true;
            }
            if (nextLayerPageheaders.has(valveLayer)) {
                presentAfter = true;
            }

        }

        return { before: presentBefore, current: presentCurrent, after: presentAfter };


    }

    const failedStackPageDirections = (): { before: boolean, current: boolean, after: boolean } => {

        const failedStackIds = Object.values(failedValves).map(valve => valve.stackId);
        if (failedStackIds.length === 0) {
            return { before: false, current: false, after: false };
        }


        let priorStacksPageheaders = new Set();
        if (stackPage === 0) {
            priorStacksPageheaders = new Set();
        }
        else {
            const _priorStackSlice = stackHeaders;
            priorStacksPageheaders = new Set(_priorStackSlice);
        }
        const currentStacksPageHeaders = new Set(stackHeaders);

        const nextStacksPageheaders = new Set(stackHeaders);

        let presentBefore = false;
        let presentCurrent = false;
        let presentAfter = false;

        for (const valveStack of failedStackIds) {
            if (priorStacksPageheaders.has(valveStack)) {
                presentBefore = true;
            }
            if (currentStacksPageHeaders.has(valveStack)) {
                presentCurrent = true;
            }
            if (nextStacksPageheaders.has(valveStack)) {
                presentAfter = true;
            }

        }

        return { before: presentBefore, current: presentCurrent, after: presentAfter };

    }

    const stackHeaders = stacksData.map(stack => stack.id);
    const _uniqueLayers = new Set(stacksData.flatMap(stack => stack.valves).map(valve => valve?.number))
    const layerHeaders = Array.from(_uniqueLayers);

    const numFillerStacks = calcExtraNeeded(stacksData.length, stacksPerPage);
    const needFillerStacks = stackPage === endStackPage;
    const numFillerLayers = calcExtraNeeded(layers.length, layersPerPage);
    const needFillerLayers = layerPage === endLayerPage;

    const fillerLayers = Array.from({ length: numFillerLayers }).fill(null).map((empty, index) => {
        return <tr key={`filler${index}`}>
            <th className="header-column">

            </th>
        </tr>
    });

    const isValveCovered = (valve: ValveDTO) => {
        const layers = props.binDTO?.layers ?? [];
        for (const layer of layers) {
            if (valve.number === (layer.number) && layer.hasGrain) {
                return true;
            }
        }
        return false;
    }

    return <>
        <Row >
            <Col xs={24}>
                {isProcessingStackData && <>
                    <h1>Submitting stack changes... <RedoOutlined spin /></h1>
                </>}

                {checkForNewStackData && <>
                    <Space direction="horizontal" align='center'>
                        <h1>Applying Valve Changes <RedoOutlined spin /></h1>

                    </Space>
                </>}


                {!checkForNewStackData && !isProcessingStackData && <>
                    <div style={{ overflowX: 'auto', paddingTop: "8px", paddingBottom: "8px" }}>
                        <table className="stackTable">


                            <thead>
                                <tr key="row-header-and-controls" style={{ width: "60px" }}>
                                    <td key={"layerSelect"} className="header-column">
                                    </td>
                                    {stacksData?.map((stack, i) => {
                                        return <th key={stack.id} className="header-row">
                                            <Button disabled={!allowChangingStacks} onClick={(event) => handleStackSelect(event, stack.id!)} style={{ width: "60px", height: "48px", fontSize: 18, border: "1px solid gray" }}>
                                                <b>{stack.id}</b>
                                            </Button>
                                        </th>
                                    })}
                                    {needFillerStacks && Array.from({ length: numFillerStacks }).fill(null).map((empty, index) => <th className="header-row" key={numFillerStacks + index}></th>)
                                    }
                                </tr>
                            </thead>

                            <tbody>
                                {needFillerLayers && fillerLayers}
                                {[...layers].reverse().map((layer) => {
                                    const layerSelector = <th key={layer.layerId} >
                                        <Button disabled={!allowChangingStacks} onClick={(event) => handleLayerSelect(layer.layerId!)} style={{ width: "60px", height: "48px", fontSize: 18, border: "1px solid gray" }}>
                                            <b>{layer.layerId}</b>
                                        </Button>
                                    </th>

                                    const valveCells = layer?.valves?.map((valve, valveIndex) => {
                                        const hasValve = valve.id != null;
                                        const covered = isValveCovered(valve);
                                        const hasValveStyle = hasValve ? "valve" : "";
                                        const isSelected = selectedValves.hasOwnProperty(valve.id!);
                                        const selectedStyle = isSelected ? "valveSelected" : "";

                                        const failed = valveFailed(valve.id!, valve.positionLabel as ValveState);
                                        const failedStyle = failed ? "valveFailed" : "";
                                        let badgeColor = covered ? "green": "red";
                                        const positionStyle = lookupPositionToShow(valve.id!, valve.positionLabel as ValveState);
                                        const disabledSelectValveStyle = !props.allowEdits ? 'valveDisabled' : '';

                                        return <td className={`${positionStyle} ${hasValveStyle} ${failedStyle} ${selectedStyle} ${disabledSelectValveStyle}`} key={`${layer.layerId!}*${stackPage}+${valveIndex}`}
                                            onClick={() => handleValveClick(valve)}
                                        >
                                            {hasValve && getIcon(lookupPositionToShow(valve.id!, valve?.positionLabel as ValveState))}
                                            {hasValve && <GrainCoveredDot covered={covered} inIcon={true}/>}
                                        </td>
                                    });

                                    return <tr key={layer.id!}>

                                        {layerSelector}
                                        {valveCells}
                                    </tr>
                                })
                                }

                            </tbody>

                        </table>
                    </div>

                    {allowChangingStacks && <div className="controls" style={{ display: "flex", flexDirection: "row", gap: 16, paddingTop: "16px" }}>
                        <Button size="large" onClick={() => handleQueueActions(ValveState.CLOSED)} icon={<Icon component={ClosedIcon} style={{ width: 16, height: 16 }} />}>Close</Button>
                        <Button size="large" onClick={() => handleQueueActions(ValveState.FLOW)} icon={<Icon component={FlowIcon} style={{ width: 16, height: 16 }} />}>Flow</Button>
                        <Button size="large" onClick={() => handleQueueActions(ValveState.VENT)} icon={<Icon component={VentIcon} style={{ width: 16, height: 16 }} />}>Vent</Button>
                        <Button size="large" onClick={() => handleQueueActions(ValveState.EXHAUST)} icon={<Icon component={ExhaustIcon} style={{ width: 16, height: 16 }} />}>Exhaust</Button>

                        <div style={{ paddingLeft: "8px" }} />
                        <Button size="large" type='primary'
                            onClick={() => submitNewValvesState()}
                            disabled={disableSubmission || props.binDTO?.operatingMode !== OperatingMode.Manual || !hasValvesToSubmit || turnOnCompressorMutation.isLoading}>Submit</Button>
                        <Button size="large" onClick={() => { setDesiredValveStates({}) }} disabled={disableSubmission || !hasValvesToSubmit}>Discard</Button>
                    </div>}
                </>
                }

            </Col>
        </Row>
    </>;
}