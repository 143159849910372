import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Card, Col, message, Radio, Row, Spin, Tooltip, Typography } from "antd"
import React from "react"
import BinApiService from "src/api/BinApiService";
import CompressorMode from "src/consts/CompressorMode";
import CompressorOffReason from "src/consts/CompressorOffReason";
import BinDTO from "src/models/BinDTO";
import { usePermissions } from "src/pages/features/usePermissions";
import { OperatingMode } from "./BinVisualThree";
import { formatNumber } from "./HeaterControls";


export const useTurnOffCompressorMutation = (deviceId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            const result = await BinApiService.turnOffCompressor(deviceId);
            return { success: result };
        }
    });
};

export const useTurnOnCompressorMutation = (deviceId: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            const result = await BinApiService.turnOnCompressor(deviceId);
            return { success: result };
        }
    });
};

interface CompressorControlProps {
    binId: number;
    azureDeviceId: string;
    binDTO: BinDTO | null | undefined;
}

export const CompressorControls = (props: CompressorControlProps) => {

    const ambientTempF = props.binDTO?.ambientAir?.temp;
    const compressorPSI = props.binDTO?.compressorState?.inlinePressure?.psi;
    const permissions = usePermissions();

    const formatAmbientText = (temp: number | null | undefined) => {
        if (temp == null || !Number.isFinite(temp)) {
            return "No Data";
        }
        return `${temp.toFixed(0)}°`;
    };

    const formatCompressorText = (psi: number | null | undefined) => {
        if (psi == null || !Number.isFinite(psi)) {
            return "No Data";
        }
        return <Typography.Text strong>{`${psi.toFixed(0)}`}</Typography.Text>;
    };


    const formatCompressorStatus = (binDTO: BinDTO | null | undefined) => {
        // compressirOn: true/false
        // compressorOffReason: enum, null if compressor is on
        // cycle start time. compresosor will turn on in HH:MM

        const compressorStatus = binDTO?.compressorState?.isOn;
        if (compressorStatus === true) {
            return <Typography.Text>Compressor is <Typography.Text strong>ON</Typography.Text></Typography.Text>;
        }
        else if (compressorStatus === false) {
            return <Typography.Text>Compressor is <Typography.Text strong>OFF</Typography.Text></Typography.Text>;
        }
        return "Uknown";
    }

    const formatCompressorOffReason = (binDTO: BinDTO | null | undefined) => {
        const compressorOffReason = binDTO?.compressorState?.offReason;
        if (binDTO?.compressorState?.offReason === CompressorOffReason.RestBetweenCycles) {
            return <Typography.Text>Compressor is cycling for 3 minutes to cool down & will resume</Typography.Text>
        }
        else {
            return <Typography.Text>{compressorOffReason}</Typography.Text>
        }
    }

    const turnOffCompressorMutation = useTurnOffCompressorMutation(props.azureDeviceId);
    const turnOnCompressorMutation = useTurnOnCompressorMutation(props.azureDeviceId);
    const allowCompressorControl = props.binDTO?.operatingMode === OperatingMode.Manual;

    const getCompressorDisabledText = () => {
        if (!permissions.canWrite) {
            return "View Only";
        }
        if (!allowCompressorControl) {
            return "Controllable in Dry Mode or User Control mode";
        }
        return '';
    }

    const compressorControlText = getCompressorDisabledText();

    return <Card title="Compressor Settings">

        <Row gutter={[64, 64]}>
            <Col md={24} lg={12}>
                <div style={{ width: "240px" }}>
                    <Tooltip trigger={['click', 'hover']} title={compressorControlText}>
                        <Radio.Group disabled={!(allowCompressorControl && permissions.canWrite)} buttonStyle="solid" value={props.binDTO?.compressorState?.desiredIsOn ?? null}>
                            <Radio.Button onClick={() => {
                                turnOffCompressorMutation.mutate(undefined, {
                                    onSuccess: (result) => {
                                        if (result.success === true) {
                                            message.success('Requested compressor to turn Off.');
                                        } else {
                                            message.destroy();
                                            message.error('Compressor did not turn off');
                                        }
                                    }, onError: (error) => {
                                        message.error('Failed to turn off compressor');
                                        console.error('Failed to turn off compressor', error);

                                    }
                                })
                            }} value={false}>Off</Radio.Button>
                            <Radio.Button value={true} onClick={() => {
                                turnOnCompressorMutation.mutate(undefined, {
                                    onSuccess: (result) => {
                                        if (result.success === true) {
                                            message.success('Requested compressor to turn On.');
                                        } else {
                                            message.destroy();
                                            message.error('Compressor did not turn On');
                                        }
                                    }, onError: (error) => {
                                        message.error('Failed to turn on compressor');
                                        console.error('Failed to turn on compressor', error);
                                    }
                                })
                            }}>On</Radio.Button>
                        </Radio.Group>
                    </Tooltip>
                    {(turnOffCompressorMutation.isLoading || turnOnCompressorMutation.isLoading) && <Spin />}
                </div>

                <br />

                <Typography.Title level={5}>Automated Activation</Typography.Title>
                <Typography.Paragraph>
                    <Typography.Text>The compressor is used to control the valves & will automatically turn on when the valve controls are activated.</Typography.Text>
                </Typography.Paragraph>

                <Typography.Title level={5}>Temp Warning</Typography.Title>
                <Typography.Paragraph>
                    <Typography.Text>If the ambient temp is below <Typography.Text strong>32°F</Typography.Text> various parts of the system are at <Typography.Text underline style={{ whiteSpace: "nowrap" }}>high risk</Typography.Text> of damage.</Typography.Text>
                </Typography.Paragraph>
            </Col>


            <Col md={24} lg={12}>
                <Typography.Title level={5}>Sensor Readings</Typography.Title>
                <Typography.Text>Ambient Air Temp: <Typography.Text strong>{formatAmbientText(ambientTempF)}</Typography.Text></Typography.Text>
                <Typography.Text style={{ display: "block" }}>PSI: {formatCompressorText(compressorPSI)}</Typography.Text>
                {(props.binDTO?.hardwareYear ?? 0) >= 2022 && <Typography.Paragraph>
                    Current: <Typography.Text strong>{formatNumber(props.binDTO?.compressorState?.current?.current_mA! / 1000, { decimalPlaces: 2 })} Amps</Typography.Text>
                </Typography.Paragraph>}

                {(ambientTempF == null || ambientTempF <= 32) && <section> <br /><Typography.Paragraph strong>WARNING - HIGH RISK OF SYSTEM DAMAGE!!!</Typography.Paragraph> </section>}


                <Typography.Title level={5}>Compressor Status</Typography.Title>
                <Typography.Text style={{ display: "block" }}>{formatCompressorStatus(props.binDTO)}</Typography.Text>
                <Typography.Paragraph>
                    <Typography.Text>{formatCompressorOffReason(props.binDTO)}</Typography.Text>
                </Typography.Paragraph>
            </Col>
        </Row>
   </Card>
}