import Table, { ColumnProps } from "antd/es/table";
import { useBinDTOContext } from "src/queries/BinDTOContext"
import { formatNumber } from "../../HeaterControls";
import _ from "lodash";
import React from "react";
import { Card, Flex } from "antd";
import BinDTO from "src/models/BinDTO";
import TemperatureSensorEnum from "src/consts/TemperatureSensorEnum";
import { DataTransformer } from "./dataTransformation";
import { SensorNumberWidth, SensorNameWidth } from "../shared";

export const TemperatureCableTable = (props: {}) => {

    const binDTOContext = useBinDTOContext();
    const normalizedData = useTranformTemperatureSensorData(binDTOContext.binDTO!)

    if (binDTOContext.binDTO == null) {
        console.log("bindto is null in temperatureCableTable");
        return null;
    }

    const columns: ColumnProps<SensorDataType[0]>[] = [];
    columns.push({
        width: SensorNumberWidth,
        align: "center",
        dataIndex: ["number"],
        render(value, record, index) {
            return <span style={{ fontWeight: "bold" }}>{value}</span>
        },
    })

    for (const [cableIndex, cable] of (normalizedData.cableIds ?? [])?.entries()) {
        columns.push({
            title: cable.shortDisplayName,
            width: SensorNameWidth,
            align: "center",
            dataIndex: ["sensors", cableIndex, "temperature"],
            render(value, record, tableIndex) {
                return <span>{formatNumber(value, { decimalPlaces: 0, suffix: "°", filler: "" })}</span>;
            },
        });
    }

    if (normalizedData.cableIds.length === 0) {
        return null;
    }

    return <Card styles={{ body: { padding: 0, overflow: "auto" } }} size="small" title={`Temperature Cable Readings (F°)`}>
        <div style={{ minWidth: 128 }}>
            <Table tableLayout="fixed" size="small" pagination={false} dataSource={normalizedData.sensorData} columns={columns}
                scroll={{ x: "max-content" }}
                style={{ tableLayout: "fixed", overflowX: 'auto', width: "max-content", minWidth: "max-content" }}
            />
        </div>
    </Card>
}

export type SensorDataType = Array<{ number: number | null, sensors: Array<{ cableId: string | null, sensorId: string | null, temperature: number | null, mc?: number | null}> }>;
export interface temperatureDataTranposed {
    cableIds: Array<{ cableId: string | null, shortDisplayName: string | null }>,
    sensorData: SensorDataType,
}
const useTranformTemperatureSensorData = (bindto: BinDTO): temperatureDataTranposed => {

    const dataTransformer = new DataTransformer();
    if (bindto == null) {
        return {
            cableIds: dataTransformer.cableIds,
            sensorData: dataTransformer.sensorLayers,
        };
    }

    if (bindto.temperatureSensorsType === TemperatureSensorEnum.Thermocouple) {
        for (let [cableIndex, cable] of bindto.temperatureCables?.entries() ?? []) {
            dataTransformer.populateCableInfo(cable);
            for (let [sensorIndex, sensor] of cable?.thermocouples?.entries() ?? []) {
                dataTransformer.populateSensor(cable, sensor);
            }
        }
    }

    else if (bindto.temperatureSensorsType === TemperatureSensorEnum.Opi) {
        for (let [cableIndex, cable] of bindto.opiMoistureCables?.entries() ?? []) {
            dataTransformer.populateCableInfo(cable);
            for (let [sensorIndex, sensor] of cable?.rhtStates?.entries() ?? []) {
                dataTransformer.populateSensor(cable, sensor);
            }
        }
    }

    else if (bindto.temperatureSensorsType === TemperatureSensorEnum.BinSense) {
        for (let [cableIndex, cable] of bindto.binSenseMoistureCables?.entries() ?? []) {
            dataTransformer.populateCableInfo(cable);
            for (let [sensorIndex, sensor] of cable?.rhtStates?.entries() ?? []) {
                dataTransformer.populateSensor(cable, sensor);
            }
        }
    }

    else if (bindto.temperatureSensorsType === TemperatureSensorEnum.PowerCast) {
        for (let [cableIndex, cable] of bindto.moistureCables_RF?.entries() ?? []) {
            dataTransformer.populateCableInfo(cable);
            for (let [sensorIndex, sensor] of cable?.wirelessStates?.entries() ?? []) {
                dataTransformer.populateSensor(cable, sensor);
            }
        }
    }

    dataTransformer.sensorLayers.reverse();

    return {
        cableIds: dataTransformer.cableIds,
        sensorData: dataTransformer.sensorLayers,
    };

}