import CurrentUser from "src/utils/CurrentUser";
import React, { PropsWithChildren, useMemo } from 'react';
import { Tooltip } from "antd";

export const usePermissions = () => {

  const canWrite = CurrentUser.Get()?.canModifyControls() ?? false;
  const permissions = useMemo(() => {
    return {
      canWrite: canWrite,
      canRead: true,
    }
  }, [canWrite]);
  return permissions;
}
