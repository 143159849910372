import { useQueryClient, useQueries, useQuery } from "@tanstack/react-query";
import {Button, Space, Table, Input } from "antd";
import dayjs from "dayjs";
import React, { useRef, useCallback, useState, useMemo } from "react";
import { CSVLink } from "react-csv";
import Link from 'antd/es/typography/Link';

import Routes from "src/consts/Routes";
import { ExtendedColumnType, useColumnExpansion, binDBKeys } from "./BinCommander";
import ShivversService from "src/api/ShivversService";
import ReadingDTO from "src/models/ReadingDTO";
import BinInfoDTO from "src/models/BinInfoDTO";
import AdminApiService from "src/api/AdminApiService";
import { CheckFailedIcon, CheckPassedIcon } from "../features/WeatherMonitorAntdOnly";
import { DEBOUNCE_TIME_MS } from "../users/UserList";
import { useDebouncedCallback } from "use-debounce";

export const useAllPremierBinsQuery = (options: { enabled?: boolean, selector?: (data: BinInfoDTO[]) => BinInfoDTO[] } = {}) => {
    const query = useQuery({
        queryKey: ["allshivversBins", "bininfo"],
        queryFn: async (q) => await AdminApiService.getAllPremierBins(q.signal),
        refetchOnWindowFocus: false,
        enabled: options.enabled,
        select: (data) => {
            if (options?.selector == null) {
                return data;
            }
            return options.selector(data);
        },
        onError: (err) => {
            console.error(err);
        },
    },
    )
    return query;
}

export const PremierSpreadsheet = () => {
    const [nameSearch, setNameSearch] = useState("");
    const nameSearchDelayedValue = useDebouncedCallback((value) => {
        setNameSearch(value);
    }, DEBOUNCE_TIME_MS);

    const queryClient = useQueryClient();
    const binsQuery = useAllPremierBinsQuery();

    // https://stackoverflow.com/a/68066447
    const csvLinkRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null); // setup the ref that we'll use for the CSVLink click once we've updated the filename

    const latestreadingsQuery = useQueries({
        queries: binsQuery.data?.filter(binInfo => !(binInfo.linkIpAddress == null)).map(binInfo => {
            return {
                queryKey: [...binDBKeys.shivversBin(binInfo.linkIpAddress!), { last: 1 }],
                queryFn: async (q: any) => {
                    const result = await ShivversService.lastReadings(binInfo.linkIpAddress!, 1, q.signal);
                    // share to bin
                    if (result != null) {
                        queryClient.setQueryData([...binDBKeys.shivversBin(binInfo.linkIpAddress!), { last: 1 }], result);
                    }
                    return result?.[0];
                }
            };
        }) ?? []
    });

    const pending = latestreadingsQuery.reduce((acc, obj) => { return acc + Number(obj.isLoading) }, 0);

    const columns: Array<ExtendedColumnType<ReadingDTO & BinInfoDTO & { account: string | null }>> = [
        {
            dataIndex: "binName",
            title: "Bin Name",
            fixed: "left",
            sortType: 'string',
            render(value, record, index) {
                return <div>
                    <Link href={`${Routes.BIN_STATS_SHIVVERS}/${record.externalId}`} target="_blank">{value}</Link>
                </div>
            },
        },
        {
            dataIndex: "dateinUtc",
            title: "Last Reading",
            width: "160px",
            sorter: (rowA, rowB, sortOrder) => {
                // put nulls at end of sorts
                // https://github.com/TanStack/table/discussions/2371#discussioncomment-210260
                if (!rowA.dateinUtc && !rowB.dateinUtc) {
                    return 0;
                }

                if (!rowA.dateinUtc) {
                    return sortOrder === "descend" ? -1 : 1;
                }

                if (!rowB.dateinUtc) {
                    return sortOrder === "descend" ? 1 : -1;
                }
                return rowA.dateinUtc?.localeCompare(rowB.dateinUtc);
            },
            render(value, record, index) {
                if (value == null) {
                    return null;
                }
                var date =  dayjs(value)
                if (!date.isValid()) {
                    return null;
                }
                return <span>{date.format("hh:mm A MM/DD/YYYY")}</span>;
            },
            sortType: 'date',
            defaultSortOrder: "descend",
        },
        {
            dataIndex: "externalId",
            hidden: true,
        },
        {
            title: "Account",
            dataIndex: 'account',
            sortType: "string",
        },
        {
            title: "Moisture",
            dataIndex: "moistread",
            align: 'right',
        },
        {
            title: "Avg. Moisture",
            dataIndex: "moistavg",
            align: 'right',
        },
        {
            title: "Grain Temp",
            dataIndex: "graintemp",
            align: 'right',
        },
        {
            title: "Plenum Temp",
            dataIndex: 'plenumtemp',
            align: 'right',
        },
        {
            title: "Target Temp",
            dataIndex: 'targettemp',
            align: 'right',
        },
        {
            title: "Machine",
            dataIndex: "machine",
            align: 'center',
            sortType: "string",
            render: (value, record, index) => {
                return <Space direction="horizontal" wrap size="small">
                    {record?.machine}
                    {record?.machine != null ? record?.machine?.includes("ON") ? <CheckPassedIcon /> : <CheckFailedIcon /> : null}
                </Space>;
            },
            filters: [
                {
                    text: "ON",
                    value: "ON"
                },
                {
                    text: "OFF",
                    value: "OFF"
                }
            ],
            onFilter: (value, record) => value.toString().trim() === record.machine?.trim(),
        },
        {
            title: "Static Pressure",
            dataIndex: "pressure",
            align: 'right',
        },
    ];

    const dataSources = [...(binsQuery?.data ?? [])].map((bininfo, index) => {

        const reading = latestreadingsQuery.find(latest => latest.data?.ip == bininfo?.linkIpAddress)
        return {
            ...(reading?.data ?? {}),
            externalId: bininfo?.externalId,
            account: bininfo?.linkIpAddress,
            binName: bininfo?.name,
            loading: reading?.isLoading,
            binLink: `${window.location.origin}${Routes.BIN_STATS_SHIVVERS}/${bininfo?.externalId}`,
        }
    });

    let transformed = useColumnExpansion({ columns: columns, datasource: dataSources });

    const generateCSVFilename = useCallback(() => {

        return `PremierOverview ${dayjs().format("YYYY-MM-DD HH_mm_ss")}.csv`;
    }, []);

    const updateFilters = () => {
        if (nameSearch === "") {
            return dataSources ?? [];
        }

        const filteredTable = dataSources
            ?.filter((record) => record.binName?.toUpperCase()?.startsWith(nameSearch.toUpperCase())
                || record.binName?.split(" ")
                    ?.some(part => part?.toUpperCase()?.startsWith(nameSearch.toUpperCase())
                    )
                || record.account === (nameSearch)
            )
            ?? [];
        return filteredTable;
    };

    const finalTable = useMemo(() => updateFilters(), [nameSearch, dataSources]);

    return (<>
        <Space direction="horizontal" size='large'>
            <div>Bins still loading: {pending}</div>

            <Input style={{ width: 240 }} onChange={(evt) => nameSearchDelayedValue(evt.target.value)} placeholder='Search by Name or Account'></Input>

            <Button>
                <CSVLink ref={csvLinkRef} filename={generateCSVFilename()}
                    onClick={() => {
                        let downloadAttr = csvLinkRef.current?.link;
                        if (downloadAttr) {
                            downloadAttr.download = generateCSVFilename();
                        }
                    }}
                    data={dataSources} headers={[
                        { label: "Bin Name", key: "binName" },
                        { label: "Date", key: "datein" },
                        { label: "Account", key: 'account' },
                        { label: "Moisture", key: "moistread" },
                        { label: "Moisture Avg.", key: "moistavg" },
                        { label: "Grain Temp", key: "graintemp" },
                        { label: "Plenum Temp", key: "plenumtemp" },
                        { label: "Target Temp", key: "targettemp" },
                        { label: "Machine", key: "machine" },
                        { label: "Static Pressure", key: "pressure" },
                        { label: "Bin page link", key: "binLink" },
                        { label: "Loading", key: "loading" },
                    ]}>Download CSV</CSVLink>
            </Button>
        </Space>
        <Table style={{ paddingTop: "16px" }} dataSource={finalTable} columns={transformed as any}
            scroll={{ x: '100%' }}
            sticky size="small"
            pagination={{ pageSize: 200, size: "default" }}
        >
        </Table>
    </>);
}