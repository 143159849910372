import { QuestionCircleOutlined, SaveFilled } from "@ant-design/icons";
import { Button, Col, Form, FormInstance, InputNumber, Popover, Radio, Row, Space, Tag, Typography } from "antd";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import dayjs, { Dayjs } from "dayjs";
import produce from "immer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ManualFanMode from "src/consts/ManualFanMode";
import ReportedFanSettingsDTO from "src/models/ReportedFanSettingsDTO";
import { Spinner } from "./CustomSpin";
import { useForm } from "antd/es/form/Form";
import BinDTO from "src/models/BinDTO";
import StatePicker from "../shared/StatePicker";
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useQuery } from '@tanstack/react-query';
import { round } from "lodash-es";
import { usePermissions } from "./usePermissions";
dayjs.extend(duration);
dayjs.extend(relativeTime);


export const defaultFormValues: SettingsFormValues = {
    mode: ManualFanMode.AutoWeather,
    minEMC: 0,
    maxEMC: 16,
    minTemp: 32,
    maxTemp: 105,
    fanRemainingTimeOn: null
};

export const populateInitialValues = (current: Partial<ReportedFanSettingsDTO> | null | undefined, fanRemainingOnTimeSeconds: number | null | undefined) => {
    console.log("current settings: ", current);
    const nextState = produce(defaultFormValues, initialValues => {
        if (current == null) {
            return;
        }
        const maybeFanRemainingOnTime = (fanRemainingOnTimeSeconds ?? defaultFormValues.fanRemainingTimeOn);
        initialValues.mode = current.mode ?? defaultFormValues.mode;
        initialValues.minEMC = current.minEMC ?? defaultFormValues.minEMC;
        initialValues.maxEMC = current.maxEMC ?? defaultFormValues.maxEMC;
        initialValues.minTemp = current.minTemp ?? defaultFormValues.minTemp;
        initialValues.maxTemp = current.maxTemp ?? defaultFormValues.maxTemp;
        initialValues.fanRemainingTimeOn = maybeFanRemainingOnTime != null ? round(maybeFanRemainingOnTime / 3600, 2) : null;
    });
    console.log("using these for initial values: ", nextState);
    return nextState;
};


export interface ReportedFormFanSettings {
    mode: ManualFanMode | null;
    lastUpdated: string | null;
    minEMC: number | null;
    maxEMC: number | null;
    minTemp: number | null;
    maxTemp: number | null;
}

interface WeatherMonitorFormProps {
    binDTO: BinDTO | null | undefined,
    deviceId: string,
    newFanSettings: Partial<ReportedFormFanSettings> | null | undefined,
    onSubmit: (values: SettingsFormValues) => Promise<boolean>,
    onCancel?: () => void;
    form?: FormInstance<SettingsFormValues>;
    greyControl: boolean
}


export const WeatherMonitorForm = (props: WeatherMonitorFormProps) => {
    const binDto = props.binDTO;

    const originalFanRemainingOnTimer = props.binDTO?.fanOperations?.originalFanRemainingOnTimeSeconds == null ?
     0 : props.binDTO?.fanOperations?.originalFanRemainingOnTimeSeconds / 3600;
    
     const timerIsRunning = props.binDTO?.fanOperations?.desiredFanOn && !props.binDTO?.fanOperations?.ignoreFanRemainingOnTime && props.binDTO?.fanOperations?.offReason == null;

     // const queryClient = useQuery();
    const [form] = useForm(props.form);
    const lastUpdated = props.newFanSettings?.lastUpdated ?? new Date().toISOString();
    // console.log("last updated being used", lastUpdated);
    // console.log("bindto in form", props.binDTO);
    const initialValues = useMemo(() => populateInitialValues(props.newFanSettings, props.binDTO?.fanOperations?.fanRemainingOnTimeSeconds), [lastUpdated, props.binDTO?.fanOperations?.fanRemainingOnTimeSeconds, props.binDTO?.fanOperations?.fanRemainingOnTimeId]);

    const [, forceUpdate] = useState<object | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionDate, setSubmissionDate] = useState<null | Dayjs>();
    const [pendingMode, setPendingMode] = useState<ManualFanMode | null>(null);

    const permissions = usePermissions();

    const setAllNotTouched = useCallback((values: SettingsFormValues) => {

        console.log("setting form values manually using values: ", values, "ORRR inital values: ", initialValues);
        const defaults = initialValues;
        //form.resetFields();
        form.setFields([
            {name: "minEMC", touched: false, value: values.minEMC ?? defaults.minEMC},
            {name: "maxEMC", touched: false, value: values.maxEMC ?? defaults.maxEMC},
            {name: "minTemp", touched: false, value: values.minTemp ?? defaults.minTemp},
            {name: "maxTemp", touched: false, value: values.maxTemp ?? defaults.maxTemp},
            {name: "mode", touched: false, value: values.mode ?? defaults.mode},
            {name: "fanRemainingTimeOn", touched: false, value: originalFanRemainingOnTimer != null ? originalFanRemainingOnTimer : defaults.fanRemainingTimeOn},
        ])
    }, [form, initialValues]);

    const resetForm = useCallback((newValues: SettingsFormValues) => {
        console.log("resetting form");
        setAllNotTouched(newValues);
    }, [form, initialValues])

    const cancelForm = useCallback(() => {
        resetForm(initialValues);
        props.onCancel?.();
    }, [initialValues, props.onCancel]);

  useEffect(() => {
forceUpdate({});
   }, []);

    useEffect(() => {
        if (form.isFieldsTouched(false)) {
            // don't reset form to initial values, since the user is editing them / they haven't applied
            console.log("skipping due to touched fields");
            return;
        }

        // todo: Let user know settings may take a few minutes to show properly.

        // todo: replacement for this with version number
        // if (submissionDate != null && props.newFanSettings?.ts != null && !dayjs(props.newFanSettings?.ts).isAfter(dayjs(submissionDate))) {
        //     console.log("skipping due to new settings <= submission date", submissionDate?.toDate(), props.newFanSettings);
        //     return;
        // }

        // if (previousReportedFanSettings?.ts != null && props.newFanSettings?.ts != null && previousReportedFanSettings?.ts >= props.newFanSettings?.ts) {
        //     console.log("skipping due to same TS or null", previousReportedFanSettings?.ts, props.newFanSettings?.ts);
        //     return;
        // }
        console.log("default values changed, resetting form...", initialValues, "submission date", submissionDate?.toDate(), "last updated", lastUpdated);
        // todo: Current suspicion is that antd form's initialValues don't update when different ones are passed in.
        // auto => on => auto (uses original auto values instead of submitted ones OR even the newly updated ones)
        // Also. Could be because the form fields are destroyed when going to on. and thus populated with the original initial values?

        // try setfields instead.
        resetForm(initialValues);
    }, [form, initialValues, resetForm, lastUpdated]);

    const resetToSystemDefault = useCallback(() => {

        let newObj: Parameters<FormInstance['setFields']>[0] = Object.entries(defaultFormValues).map(([k, v]) => {

            return {name: k, value: v, touched: true};
        })

          form.setFields(newObj);
    }, [form, props.binDTO])

    const onFinish = useCallback(async (values: SettingsFormValues) => {
        try {
            const valuesCopy = {...values};
            console.log("values from form", values);
            // skip sending the timer if we are in requesting a fan mode that doesn't use the timer
            // currently this is to prevent many timer elapsed notifications from getting sent if the timer is set or is 0 already.
            if (values.mode != ManualFanMode.AutoWeather) {
                valuesCopy.fanRemainingTimeOn = null;
            }
            setPendingMode(valuesCopy.mode);
            setIsSubmitting(true);
            const isSuccess = await props.onSubmit?.(valuesCopy);
            console.info("form submit status: ", isSuccess);
            if (isSuccess != null) {
                setSubmissionDate(dayjs());
                // not a mistake: Restore all original values including the timer, if submission fails
                resetForm(values);
            }
        } catch (err) {
            console.log("error while submitting fan settings to server", err);
        } finally {
            setPendingMode(null);
            setIsSubmitting(false);
        }
    }, [props.onSubmit, setIsSubmitting, setPendingMode, setSubmissionDate, resetForm]);

    const automationMode = Form.useWatch("mode", form);

    const submitFormManually = useCallback(async () => {
        try {
            const values = await form.validateFields();
            setPendingMode(values.mode);
            setIsSubmitting(true);
            const valuesCopy = {...values};
            if (values.mode != ManualFanMode.AutoWeather) {
                valuesCopy.fanRemainingTimeOn = null;
            }
            const isSuccess = await props.onSubmit?.(valuesCopy);

            console.info("form submit status: ", isSuccess, values);
            if (isSuccess === true) {
                setPendingMode(null);
                setSubmissionDate(dayjs());
                resetForm(values);
            }
            else if (!isSuccess) {
                console.log("form didn't submit successfully, resetting mode to previous: ", initialValues.mode);
                form.setFieldValue("mode", initialValues.mode);
            }
        } catch (err) {
            console.error("error validating form fields", err);
        }
        finally {
            setIsSubmitting(false);
            setPendingMode(null);
        }
    }, []);

    // const onAutomationModeChange = useCallback(async (e: RadioChangeEvent) => {
    //     if (e.target.value == ManualFanMode.AlwaysOn || e.target.value == ManualFanMode.AlwaysOff) {
    //         submitFormManually();
    //     }
    // }, [submitFormManually]);

    return ( <Form form={form} layout="vertical" disabled={!permissions.canWrite}
        preserve={true}
            onFinish={onFinish}
            initialValues={initialValues}
            scrollToFirstError={{
                block: "center",
            }}
        >
                    <Space size={"small"} direction="vertical">
                        {/* <Typography.Title level={3}>Fan Settings</Typography.Title> */}
                        {/* <Typography.Title level={5}>Desired Fan Setting</Typography.Title> */}
                        <Form.Item name="mode">
                            <Radio.Group disabled={props.greyControl} buttonStyle="solid">
                                <Radio.Button onClick={async (e) => {
                                    form.setFieldValue("mode", (e.target as HTMLInputElement).value);
                                    submitFormManually();
                                }} value={ManualFanMode.AlwaysOff}>Off <Spinner invert={true} spinning={pendingMode === ManualFanMode.AlwaysOff} /></Radio.Button>
                                <Radio.Button onClick={async (e) => {
                                    form.setFieldValue("mode", (e.target as HTMLInputElement).value);
                                    submitFormManually();
                                }} value={ManualFanMode.AlwaysOn}>On <Spinner invert={true} spinning={pendingMode === ManualFanMode.AlwaysOn} /></Radio.Button>
                                <Radio.Button value={ManualFanMode.AutoWeather} >Auto <Spinner invert={true} spinning={pendingMode === ManualFanMode.AutoWeather} /></Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        {/* {automationMode === ManualFanMode.AutoWeather && <SettingsAdjustment hidden={automationMode !== ManualFanMode.AutoWeather} isSubmitting={isSubmitting} />} */}

                        {/* keeping the form values present seems to be the only way to get them to not be present on submit... */}
                        <SettingsAdjustment hidden={automationMode !== ManualFanMode.AutoWeather} binDTO={binDto} isSubmitting={isSubmitting} reset={resetToSystemDefault} cancel={cancelForm} />
                        {/* {automationMode === ManualFanMode.AlwaysOn && <OnDescription />} */}
                        {/* {automationMode === ManualFanMode.AlwaysOff && <OffDescription />} */}
                    </Space>



                {/* <Col xs={{ span: 24 }} md={{ span: 8 }}>
                    <CurrentReadings bin={props.binDTO} fans={props.binDTO.fans} ambientAirCombined={props.binDTO.ambientAir} plenumAirCombined={props.binDTO?.plenumAir} fanRunTimeSeconds={props.binDTO.grain?.fanRunTimeSeconds} />
                </Col> */}
        </Form>
    );
};


export interface SettingsFormValues {
    minEMC: number,
    maxEMC: number,
    minTemp: number,
    maxTemp: number,
    mode: ManualFanMode,
    fanRemainingTimeOn: number | null
}

interface SettingsAdjustmentProps {
    //formIsDirty: boolean,
    binDTO: BinDTO | null | undefined,
    isSubmitting: boolean
    hidden: boolean;
    reset: () => void;
    cancel: () => void;
}

export const fillEmpty = (metric: number | null | undefined) => {
    if (metric == null) {
        return "___";
    }
    else {
        return metric;
    }

}

const EMCHelp = () => {
    return <>
        <Typography.Text>Moisture Content of air at which grain will equalize to.</Typography.Text>
    </>;
}

export const formatTotalSecondsToDays = (d: duration.Duration) => {

    const formatted = `${Math.floor(d.asDays())} day(s), ${String(d.hours()).padStart(2, '0')}:${String(d.minutes()).padStart(2, '0')}`;
    return formatted;

}

const SettingsAdjustment = (props: SettingsAdjustmentProps) => {

    const permissions = usePermissions();

    const antdForm = useFormInstance();
    const minEmcForm = Form.useWatch("minEMC", antdForm);
    const maxEmcForm = Form.useWatch("maxEMC", antdForm);
    const minTempForm = Form.useWatch("minTemp", antdForm);
    const maxTempForm = Form.useWatch("maxTemp", antdForm);
    const fanRemainingTimeOn = props.binDTO?.fanOperations?.fanRemainingOnTimeSeconds;
    const d = dayjs.duration(fanRemainingTimeOn! * 1000);
    const formatted = formatTotalSecondsToDays(d);
    const timerIsRunning = props.binDTO?.fanOperations?.desiredFanOn && !props.binDTO?.fanOperations?.ignoreFanRemainingOnTime && props.binDTO?.fanOperations?.offReason == null;
    const timerIsFinished = props.binDTO?.fanOperations?.offReason == "FanTimer" && props.binDTO?.fanOperations?.fanRemainingOnTimeSeconds == 0;
    
    const originalFanRemainingOnTimer = props.binDTO?.fanOperations?.originalFanRemainingOnTimeSeconds == null ?
    0 : props.binDTO?.fanOperations?.originalFanRemainingOnTimeSeconds / 3600;
    
    //console.log("the settings adjust form values", {minEmcForm, maxEmcForm, minTempForm, maxTempForm});
    //console.log("fields are touched: ", !antdForm.isFieldsTouched(false), "form still submitting: ", props.isSubmitting);

    const [, forceUpdate] = useState<any>();

    const formatFanEMCText = () => {

        const originalMinEMC = minEmcForm;
        const originalMaxEMC = maxEmcForm;

        let offsetMaxEMC = maxEmcForm;
        if (offsetMaxEMC != null) {
            offsetMaxEMC += props.binDTO?.weatherMonitorState?.heaterPlenumMcOffset! ?? -5;
        }
        const offsetActive = props.binDTO?.heaterOffsetShouldApplyNext;
        return <Typography.Paragraph>
            <Typography.Text>Fan will run between {fillEmpty(originalMinEMC)}% and </Typography.Text>
            <Typography.Text delete={offsetActive}>{fillEmpty(originalMaxEMC)}%</Typography.Text>
            {offsetActive && <> <Typography.Text>{fillEmpty(offsetMaxEMC)}%</Typography.Text>. <Tag color="red"> +{props.binDTO?.weatherMonitorState?.heaterPlenumMcOffset}% Heater EMC Offset</Tag></>}
            </Typography.Paragraph>


    }

    useEffect(() => {
        forceUpdate({});
    }, []);

    return <>
        <Space direction="vertical" size={12} style={{display: props.hidden? "none" : undefined}}>
            <section>
                {/* <Typography.Title level={4}>Automated Fan Settings</Typography.Title> */}
                <Typography.Paragraph>Starts and stops fan(s) based on <span style={{ whiteSpace: "nowrap" }}>weather conditions.</span></Typography.Paragraph>
            </section>

            <section>
                <Space direction="horizontal">
                    <Typography.Title level={5}>EMC Setpoint</Typography.Title>
                    <Popover content={EMCHelp} >
                        <QuestionCircleOutlined size={48} />
                    </Popover>
                </Space>
                {formatFanEMCText()}
                <Space direction="horizontal" wrap>
                    <Form.Item preserve name="minEMC" label={<Typography.Text type="secondary">Min EMC %</Typography.Text>}
                        rules={[{ type: "number", min: 0, max: 100, message: "Between 0 and 100%" }]}
                    >
                        <InputNumber size="large" placeholder="Min EMC %" addonAfter="%" style={{ width: 160 }} />
                    </Form.Item>

                    <Form.Item preserve name="maxEMC" label={<Typography.Text type="secondary">Max EMC %</Typography.Text>}
                        rules={[{ type: "number", min: 0, max: 100, message: "Between 0 and 100%" }]}
                    >
                        <InputNumber size="large" placeholder="Max EMC %" style={{ width: 160 }} addonAfter="%" />
                    </Form.Item>
                </Space>
            </section>

            <section>
                <Typography.Title level={5}>Temperature Setpoint</Typography.Title>
                <Typography.Paragraph>Fan will run between {fillEmpty(minTempForm)}℉ and {fillEmpty(maxTempForm)}℉</Typography.Paragraph>
                <Space direction="horizontal" wrap>
                    <Form.Item preserve name="minTemp" label={<Typography.Text type="secondary">Min Temp ℉</Typography.Text>}>
                        <InputNumber size="large" placeholder="Min Temp ℉" style={{ width: "160px" }} />
                    </Form.Item>

                    <Form.Item preserve name="maxTemp" label={<Typography.Text type="secondary">Max Temp ℉</Typography.Text>}>
                        <InputNumber size="large" placeholder="Max Temp ℉" style={{ width: "160px" }} />
                    </Form.Item>
                </Space>
            </section>

            {/*<section>
                <Typography.Title level={5}>Fan Timer</Typography.Title>
                <Typography.Paragraph>Fan is set to run  while within the specified temp & EMC setpoints</Typography.Paragraph>
                <Space direction="horizontal" wrap>
                    <Form.Item preserve name="fanRemainingTimeOn"  label={<Typography.Text type="secondary">Time in hours</Typography.Text>}>
                        <InputNumber size="large" placeholder="Time in hours" style={{ width: "160px" }} />
                    </Form.Item>
                </Space>

                {timerIsRunning &&
                 <Typography.Paragraph>
                    Current timer has {formatted} remaining
                    {/* <br></br> *//*}
                    {/* Original Time requested {originalFanRemainingOnTimer} hours *//*}
                 </Typography.Paragraph> 
                 }

                 {timerIsFinished &&
                    <Typography.Paragraph>
                        Timer of {originalFanRemainingOnTimer} hours has completed
                    </Typography.Paragraph>
                 }
            </section>*/}
            

            <Form.Item shouldUpdate={true}>
                {() => (
                <Row justify={"end"} gutter={8}>
                    {antdForm.isFieldsTouched(false) && <Col>
                        <Button onClick={props.cancel}>Cancel</Button>
                    </Col>
                    }
                    <Col>
                        <Button onClick={props.reset}>Reset</Button>
                    </Col>
                    <Col>
                        <Button icon={<SaveFilled />} disabled={!antdForm.isFieldsTouched(false) || props.isSubmitting} loading={props.isSubmitting} type="primary" htmlType="submit">Apply</Button>
                    </Col>
                </Row>
                )
}
            </Form.Item>
        </Space>
    </>
}

const OnDescription = () => {
    return null;
}

const OffDescription = () => {
    return null;
}