import RhSensorEnum from "src/consts/RhSensorEnum";
import BinDTO from "src/models/BinDTO";

export type SensorDataType = Array<{ number: number | null, sensors: Array<{ cableId: string | null, sensorId: string | null, temperature: number | null, mc?: number | null}> }>;
export interface CableSensorDataTranposed {
    cableIds: Array<{ cableId: string | null, shortDisplayName: string | null }>,
    sensorData: SensorDataType,
}

export class DataTransformer {
    public cableIds: CableSensorDataTranposed['cableIds'];
    public sensorLayers: SensorDataType;
    constructor() {
        this.cableIds = [];
        this.sensorLayers = [];
    }

    populateCableInfo = (cable: { id: string | null }) => {
        if (cable.id == null) {
            return;
        }
        const existingCable = this.cableIds.find(c => c.cableId == cable.id);
        if (existingCable) {
            return;
        }
        this.cableIds.push({
            cableId: cable.id,
            shortDisplayName: formatCableIdShorthand(cable.id),
        });
    }

    public populateSensor = (cable: { id: string | null }, sensor: { id: string | null, number: number | null, temperature: number | null, mc?: number | null }) => {
        if (sensor.number == null) {
            return;
        }
        const layerIndex = sensor.number - 1;
        const generateSensor = () => {
            const generated = {
                cableId: cable.id,
                sensorId: sensor.id,
                sensorNumber: sensor.number,
                temperature: sensor.temperature,
                ...(sensor.mc !== undefined) ? {mc: sensor.mc} : {},
            };
            return generated;
        }
        const generatedSensor = generateSensor();
        if (this.sensorLayers[layerIndex] == null) {
            this.sensorLayers[layerIndex] = { number: sensor.number, sensors: [generatedSensor] }
        }
        else {
            this.sensorLayers[layerIndex].sensors.push(generatedSensor);
        }
    }
}

export const formatCableIdShorthand = (cableId: string) => {
    if (cableId == null) {
        return "";
    }

    const parts = cableId?.split("-");
    if (parts[0]?.includes("R0")) {
        return `C-${parts[1]}`;
    }
    else if (parts[0]?.includes("R1")) {
        return `P-${parts[1]}`;
    }
    else {
        return cableId;
    }
}