export function getEnumKeys<T extends object>(enumObj: T): string[] {
    return Object.keys(enumObj).filter(key => isNaN(Number(key)));
}

export function getEnumKey<T extends object>(enumObj: T, which: T[keyof T]): string {
    const key = Object.keys(enumObj).find(
        (key) => enumObj[key as keyof T] === which
    );
    return key || "N/A"; 
}


export function getEnumDictionary<T extends object>(enumObj: T) 
{
    const ret = Object.keys(enumObj)
        .filter((key) => isNaN(Number(key))) 
        .map((key) => ({
        label: key, 
        value: enumObj[key as keyof typeof enumObj], 
        }));

    return ret;

} 