// Service TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
import BaseApi from './BaseApi';
import UserCreationDTO from '../models/UserCreationDTO';
import UserDetailDTO from '../models/UserDetailDTO';
import UpdateExternalUserInfoDTO from '../models/UpdateExternalUserInfoDTO';
import UpdateShivversUserIpRequestDTO from '../models/UpdateShivversUserIpRequestDTO';
import UserLookupDTO from '../models/UserLookupDTO';
import UserDTO from '../models/UserDTO';
import ShivversUserDTO from '../models/ShivversUserDTO';

export class UserApiService extends BaseApi {

    // get: api/user/lookups
    public getUsersLookup(signal?: AbortSignal): Promise<UserLookupDTO[]> {
        let url = `api/user/lookups`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UserLookupDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/user
    public getUsers(signal?: AbortSignal): Promise<UserDTO[]> {
        let url = `api/user`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UserDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/user/${userId}
    public getUser(userId: number, signal?: AbortSignal): Promise<UserDetailDTO> {
        let url = `api/user/${userId}`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UserDetailDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/user/search/${encodeURIComponent(q)}
    public searchUsers(q: string, signal?: AbortSignal): Promise<UserDTO[]> {
        let url = `api/user/search/${encodeURIComponent(q)}`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UserDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/user/lookupWithSearch?search=${encodeURIComponent(search)}
    public getLookupWithSearch(search: string, signal?: AbortSignal): Promise<UserLookupDTO[]> {
        let url = `api/user/lookupWithSearch`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (search != null) {
            url += `${prefix}search=${encodeURIComponent(search)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UserLookupDTO[]>(null, 'get', url, true, false, _signal);
    }

    // post: api/user/addUser?role=${role}
    public addUser(userCreationDetails: UserCreationDTO, role: number, signal?: AbortSignal): Promise<boolean> {
        let url = `api/user/addUser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (role != null) {
            url += `${prefix}role=${role}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, boolean>(userCreationDetails, 'post', url, true, false, _signal);
    }

    // post: api/user/updateUser
    public updateUser(user: UserDetailDTO, signal?: AbortSignal): Promise<UserDetailDTO> {
        let url = `api/user/updateUser`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UserDetailDTO>(user, 'post', url, true, false, _signal);
    }

    // post: api/user/updateExternalUserInfo
    public updateExternalUserInfo(user: UpdateExternalUserInfoDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/user/updateExternalUserInfo`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(user, 'post', url, true, false, _signal);
    }

    // post: api/user/updateShivversIpAndData
    public updateShivversIpAndData(update: UpdateShivversUserIpRequestDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/user/updateShivversIpAndData`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(update, 'post', url, true, false, _signal);
    }

    // get: api/user/getExternalUserDetail?shivversUserId=${shivversUserId}
    public getExternalUserDetail(shivversUserId: number, signal?: AbortSignal): Promise<ShivversUserDTO> {
        let url = `api/user/getExternalUserDetail`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (shivversUserId != null) {
            url += `${prefix}shivversUserId=${shivversUserId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ShivversUserDTO>(null, 'get', url, true, false, _signal);
    }
}
var service = new UserApiService();
export default service;
