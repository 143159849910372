import { HistoryOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { message, Tooltip, Popconfirm, Button } from "antd";
import React from "react";
import BinApiService from "src/api/BinApiService";
import { usePermissions } from "src/pages/features/usePermissions";
import { useBinInfoContext } from "src/queries/BinInfoContext";

export const ResetFanRuntime = (props: {}) => {
    const permissions = usePermissions();
    const binInfo = useBinInfoContext();

    const resetFanRuntimeMutation = useMutation({
        mutationFn: async () => {
            return await BinApiService.resetFanRuntime(binInfo.deviceId!);
        },
        onSuccess(data, variables, context) {
            if (data?.success) {
                message.success("Reset fan runtime");
            }
            else {
                message.error("Problem resetting fan runtime");
            }
        },
        onError(error, variables, context) {
            console.error("Error resetting fan runtime", error);
            message.error("Error resetting fan runtime");
        },
    });

    return (
        <Tooltip title="Resets fan runtime">
            <Popconfirm title="Reset fan runtime?" okText="Yes" cancelText="No" icon={null} onConfirm={() => resetFanRuntimeMutation.mutate()}>
                <Button disabled={resetFanRuntimeMutation.isLoading || !permissions.canWrite} size="small" icon={<HistoryOutlined />}></Button>
            </Popconfirm>
        </Tooltip>
    );
}