// Service TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
import BaseApi from './BaseApi';
import AdminUserSearchDTO from '../models/AdminUserSearchDTO';
import ReadingDTO from '../models/ReadingDTO';
import SettingsTextDTO from '../models/SettingsTextDTO';
import SettingsDTO from '../models/SettingsDTO';
import AlarmDTO from '../models/AlarmDTO';
import AverageCyclesDTO from '../models/AverageCyclesDTO';

export class ShivversService extends BaseApi {

    // get: api/shivvers/SearchUsers?lastName=${encodeURIComponent(lastName)}
    public searchUsers(lastName?: string | null, signal?: AbortSignal): Promise<AdminUserSearchDTO[]> {
        let url = `api/shivvers/SearchUsers`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (lastName != null) {
            url += `${prefix}lastName=${encodeURIComponent(lastName || '')}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, AdminUserSearchDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/shivvers/dealersDisplayName
    public dealersDisplayName(signal?: AbortSignal): Promise<string[]> {
        let url = `api/shivvers/dealersDisplayName`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, string[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/shivvers/Readings?ip=${encodeURIComponent(ip)}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}
    public readings(ip: string, from?: string | null, to?: string | null, signal?: AbortSignal): Promise<ReadingDTO[]> {
        let url = `api/shivvers/Readings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (ip != null) {
            url += `${prefix}ip=${encodeURIComponent(ip)}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from || '')}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to || '')}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ReadingDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/shivvers/LastReadings?ip=${encodeURIComponent(ip)}&lastAmount=${lastAmount}
    public lastReadings(ip: string, lastAmount: number, signal?: AbortSignal): Promise<ReadingDTO[]> {
        let url = `api/shivvers/LastReadings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (ip != null) {
            url += `${prefix}ip=${encodeURIComponent(ip)}`;
            prefix = '&';
        }
        if (lastAmount != null) {
            url += `${prefix}lastAmount=${lastAmount}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ReadingDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/shivvers/LastReadingsByDate?ip=${encodeURIComponent(ip)}&lastSeconds=${lastSeconds}
    public lastReadingsByTimespan(ip: string, lastSeconds: number, signal?: AbortSignal): Promise<ReadingDTO[]> {
        let url = `api/shivvers/LastReadingsByDate`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (ip != null) {
            url += `${prefix}ip=${encodeURIComponent(ip)}`;
            prefix = '&';
        }
        if (lastSeconds != null) {
            url += `${prefix}lastSeconds=${lastSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, ReadingDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/shivvers/Paragraph?ip=${encodeURIComponent(ip)}
    public paragraph(ip: string, signal?: AbortSignal): Promise<SettingsTextDTO> {
        let url = `api/shivvers/Paragraph`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (ip != null) {
            url += `${prefix}ip=${encodeURIComponent(ip)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, SettingsTextDTO>(null, 'get', url, true, false, _signal);
    }

    // get: api/shivvers/Settings?ip=${encodeURIComponent(ip)}
    public settings(ip: string, signal?: AbortSignal): Promise<SettingsDTO[]> {
        let url = `api/shivvers/Settings`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (ip != null) {
            url += `${prefix}ip=${encodeURIComponent(ip)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, SettingsDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/shivvers/Alarms?ip=${encodeURIComponent(ip)}&from=${encodeURIComponent(String(from))}&to=${encodeURIComponent(String(to))}
    public alarms(ip: string, from: string | null, to: string | null, signal?: AbortSignal): Promise<AlarmDTO[]> {
        let url = `api/shivvers/Alarms`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (ip != null) {
            url += `${prefix}ip=${encodeURIComponent(ip)}`;
            prefix = '&';
        }
        if (from != null) {
            url += `${prefix}from=${encodeURIComponent(from)}`;
            prefix = '&';
        }
        if (to != null) {
            url += `${prefix}to=${encodeURIComponent(to)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, AlarmDTO[]>(null, 'get', url, true, false, _signal);
    }

    // get: api/shivvers/CycleAverages?ip=${encodeURIComponent(ip)}&lastSeconds=${lastSeconds}
    public cycleAverages(ip: string, lastSeconds: number, signal?: AbortSignal): Promise<AverageCyclesDTO> {
        let url = `api/shivvers/CycleAverages`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (ip != null) {
            url += `${prefix}ip=${encodeURIComponent(ip)}`;
            prefix = '&';
        }
        if (lastSeconds != null) {
            url += `${prefix}lastSeconds=${lastSeconds}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, AverageCyclesDTO>(null, 'get', url, true, false, _signal);
    }
}
var service = new ShivversService();
export default service;
