import { useIsMutating, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Alert, Button, Card, Checkbox, Col, DatePicker, Flex, Form, Input, List, message, Row, Select, Space, Spin, Typography, } from "antd"
import dayjs from "dayjs"
import { userInfo } from "os"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useStore } from "react-redux"
import UserApiService from "src/api/UserApiService"
import RoleDTO from "src/models/RoleDTO"
import ShivversUserDTO from "src/models/ShivversUserDTO"
import UpdateExternalUserInfoDTO from "src/models/UpdateExternalUserInfoDTO"
import UserDetailDTO from "src/models/UserDetailDTO"
import CurrentUser from "src/utils/CurrentUser"
import RoleUtil from "src/utils/RoleUtil"
const Option = Select.Option;
import ShivversApiService from 'src/api/ShivversService';
import ExternalUserInfoDTO from "src/models/ExternalUserInfoDTO"
import { ApiError } from "src/api/ApiResultHandler"
import { useWatch } from "antd/es/form/Form"
import ShivversService from "src/api/ShivversService"
import { useHistory, useRouteMatch } from "react-router"
import Routes from "src/consts/Routes"
import HistoryUtil from "src/utils/HistoryUtil"
import { queryClient } from "src"
import { PageHeader } from "@ant-design/pro-layout"
import { RoleApiService } from "src/api/RoleApiService"
import AdminUserSearchDTO from "src/models/AdminUserSearchDTO"

export const HelpText = (props: {}) => {

    return <span style={{ fontWeight: "bold" }}>
        -To add a new user, fill in the
        information and click the save
        button on the bottom of the
        page.
        <br />
        -To edit an existing user, enter a
        portion of the first name, last name, or the entire account and click
        on the search button. 

        -When you have completed changes,
        check the Edit User checkbox and
        then press the Save button.
    </span>
}

const carrierOptions: Array<{ label: string, value: string }> = [
    { label: "None", value: "None" },
    { label: "Verizon", value: "Verizon" },
    { label: "AT&T", value: "AT&T" },
    { label: "Sprint", value: "Sprint" },
    { label: "US Cellular", value: "US Cellular" },
    { label: "T-Mobile", value: "T-Mobile" },
    { label: "Blue Grass", value: "Blue Grass" },
    { label: "Thumb", value: "Thumb" },
    { label: "Chat Mobility", value: "Chat Mobility" },
    { label: "Illinois Valley Cellular", value: "Illinois Valley Cellular" },
    { label: "CellCom", value: "CellCom" },
    { label: "Metro PCS", value: "Metro PCS" },
    { label: "Chariton Valley", value: "Chariton Valley" },
    { label: "Cricket Wireless", value: "Cricket Wireless" },
    { label: "Rogers(Canada)", value: "Rogers(Canada)" },
    { label: "Bell(Canada)", value: "Bell(Canada)" },
    { label: "Telus(Canada)", value: "Telus(Canada)" },
    { label: "Virgin Mobile Canada", value: "Virgin Mobile Canada" },
];

const securityLevelDescriptions = [
    "1 = Customer",
    "2 = Dealer",
    "3 = DSM",
    "0 = Admin",
    "5 = Super Admin",
    "6 = Hemp DSM",
];

const dsmRegions = [
    "1100 Glen Muench",
    "1700 Mark Sexton",
    "2900 Scott Graham",
    "3000 Tom Barron",
    "3300 BJ Arbuckle",
    "2100 Kyle Canady",
];

const formId = "editExternalUserForm";
const securityLevelOptions: Array<{ label: string, value: number }> = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "0", value: 0 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
];

interface ExternalUserEditProps {
    user: UserDetailDTO,
    roles: RoleDTO[],
    edit: boolean,
}

const userEditScope = [{scope: "userEdit"}] as const;
const userEditQueryKey = (userId: number) => [{purpose: "userEdit", userId: userId, ...userEditScope[0] }] as const;
const shivversUserInfoQueryKey = (externalId: number) => [{purpose: "externalUserInfo", externalId: externalId, ...userEditScope[0] }] as const;
const dealerNameQueryKey = [{purpose: "dealerNames" , ...userEditScope[0] }] as const;
const allRolesQueryKey = [{purpose: "allRoles", ...userEditScope[0]}] as const;
type FormValues = ShivversUserDTO & UpdateExternalUserInfoDTO;

export const ExternalUserEditContainer = (props: ExternalUserEditProps) => {

    const match = useRouteMatch<{id: string}>("/users/:id");

    const isEdit = match != null;
    const history = useHistory();

    console.log("user match", match);


    const userId = match ? Number.parseInt(match?.params.id!) : null;

    const query = useQuery({
        queryKey: userEditQueryKey(userId!),
        queryFn: async (q) => await UserApiService.getUser(userId!, q.signal),
        staleTime: 10_000,
        enabled: userId! > 0,
    })

    const allRoles = useQuery({
        queryFn: async (q) => await (new RoleApiService()).getRoles(q.signal),
        queryKey: allRolesQueryKey,
    })

    return <Card loading={allRoles.isLoading}><PageHeader title="Add/Edit Shivvers User" extra={<>
        <Search />
    </>}
        onBack={() => {
            history.push(Routes.USERS_MYSHIVVERS);
        }}
    >
        <ExternalUserEdit edit={isEdit} key={query.data?.id} user={query.data!} roles={allRoles.data ?? []} />
    </PageHeader>
    </Card>
}

const defaults: Partial<FormValues> = {
    lastname: "",
    firstname: "",
    account: "",
    address: "",
    carrier: "None",
    altcarrier: "None",
    altcarrier2: "None",
    altcarrier3: "None",
    altcell: "",
    altcell2: "",
    altcell3: "",
    cell: "",
    city: "",
    dealer: "",
    delayBillAuthor: "",
    delayBillNotes: "",
    donotbilldate: "",
    donotbilluntil: false,
    security: 1,
    region: 0,
    evo: "No",
    hemp: "No",
    tempmonitor: "No",
    inservice: null,

    useEmailAlt: false,
    useEmailAlt2: false,
    useEmailAlt3: false,
    useEmailPrime: false,
    txtsend: "Yes",
    editUser: false,
    emailAlt: "",
    emailAlt2: "",
    emailAlt3: "",
    emailPrime: "",
    inactiveAuthor: "",
    inactiveNotes: "",
    linkinactive: false,
    linkinactivedate: "",
    linkReplaceAuthor: "",
    linkReplaced: false,
    linkReplaceDate: "",
    linkReplaceNotes: "",
    mphone: "",
    password: "",
    phone: "",
    roles: [200],
    state: "",
    subdealer: null,
    userid: "",
    zip: "",
    updateIp: false,

}

export const ExternalUserEdit = (props: ExternalUserEditProps) => {

    const securityInfo = CurrentUser.Get();

    const options = props.roles?.map?.(role => {
        return { value: role.id, label: <span>{role.name}</span> }
    }) ?? [];

    const [errorText, setErrorText] = useState<string | null>(null);
    const [form] = Form.useForm<ShivversUserDTO & UpdateExternalUserInfoDTO>();

    const formValues = useWatch([], form);

    const reset = useCallback(() => {

        form.setFieldsValue(defaults);
    }, [form]);

    const handleApiError = useCallback((error: unknown) => {
        const isApiError = error instanceof ApiError;
        if (isApiError) {
            setErrorText((error?.errorDetails)?.detail || error?.message);
        } else {
            setErrorText(JSON.stringify(error, undefined, 2));
        }
    }, [setErrorText]);


    const externalDetailsQuery = useQuery({
        queryFn: async (q) => await UserApiService.getExternalUserDetail(Number.parseInt(props.user?.externalLogins?.[0]?.providerKey!, 10), q.signal),
        queryKey: shivversUserInfoQueryKey(Number.parseInt(props.user?.externalLogins?.[0]?.providerKey!, 10)),
        staleTime: 10_000,
        enabled: props.user?.id > 0 && props.edit,
    })

    const dealerNameQuery = useQuery({
        queryFn: async (q) => await ShivversApiService.dealersDisplayName(q.signal),
        queryKey: dealerNameQueryKey,
        staleTime: 10_000,
    })

    const dealerNames = dealerNameQuery.data?.map(val => ({ label: val, value: val })) ?? [];

    const submitFormMutation = useMutation({
        mutationFn: async (params: { userId: number, values: ShivversUserDTO & UpdateExternalUserInfoDTO }) => {
            return await UserApiService.updateExternalUserInfo({
                userId: params.userId,
                roles: params.values.roles,
                editUser: params.values.editUser,
                updateIp: params.values.updateIp,
                shivversUserInfo: params.values,
            });
        },
        onSuccess(data, variables, context) {
            console.log("success submitting form")
            queryClient.invalidateQueries(userEditScope);
            if (variables?.values?.editUser) {
                message.success(`Updated User`);
            }
            if (variables?.values?.editUser === false) {
                message.success("Added User");
                HistoryUtil.push(Routes.USERS_BASE);
            }
            setErrorText(null);
        },
        onError(error, variables, context) {
            handleApiError(error);
            console.log("error submitting form", error);
        },
    })

    const mutateUpdateIp = useMutation({
        mutationKey: ['updateIp'],
        mutationFn: async (params: { externalId: number ,oldIp: string, newIp: string }) => await UserApiService.updateShivversIpAndData({
            oldIp: params.oldIp,
            newIp: params.newIp,
            externalId: params.externalId,
        }),
        onSuccess(data, variables, context) {
            setErrorText(null);
            message.success(`Updated account to ${variables?.newIp}`);
            queryClient.invalidateQueries(userEditScope);
        },
        onError(error, variables, context) {
            handleApiError(error);
            console.log("error submitting update ip request", error);
        },
    });


    const onIpUpdateYesClick = () => {
        mutateUpdateIp.mutate({
            oldIp: externalDetailsQuery.data?.account ?? "",
            newIp: formValues.account ?? "",
            externalId: formValues.sequence,
        }, {
            onSuccess(data, variables, context) {
                queryClient.invalidateQueries(userEditScope);
            },
            onError(error, variables, context) {
                handleApiError(error);
                console.log("error updating ip on confirm", error);
            },
        });
    }

    const onIpUpdateCancel = () => {
        form.setFieldValue('updateIp', false);
    }


    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent form submission
        }
    };

    if (props.edit && externalDetailsQuery.data == null) {
        return <Spin />
    }

    if (formValues == null) {
        return <Spin />
    }


    const labelColSide = { span: 4 };
    const wrapperColSide = { span: 8 };

    const labelCol = { span: 4 };
    const wrapperCol = { span: 8 };

    const cellLabelCol = { span: 8 };
    const cellWrapCol = { span: 16 }

    const section3LabelCol = { span: 4 };
    const section3WrapperCol = { span: 6 };

    const section4LabelCol = { span: 4 };
    const section4WrapperCol = { span: 4 };

    const section5LabelCol = { span: 8 };
    const section5WrapperCol = { span: 8 };

    const checkLayout = {
        labelCol: {style: {width: 128}},
    }

    const initialProvidedValues = {
        roles: props.user?.roles?.map(r => r.id) ?? [200],
        ...externalDetailsQuery.data,
        updateIp: false,
        editUser: false,
    };

    const useDefaults = (props.user?.id === 0 || props.user?.id == null);

    return <Form<ShivversUserDTO & UpdateExternalUserInfoDTO> form={form} id="editExternalUserForm"
        onFinish={async (values) => {

            const checkDate = dayjs("2015-01-01T00:00:00-06:00");
            if (values.inservice == null || dayjs(values.inservice).diff(checkDate) < 0) {
                const bypassInServiceDate = confirm("Are you sure you want to save this user?  There is no in service date set.");
                if (!bypassInServiceDate) {
                    return;
                }
            }

            submitFormMutation.mutate({
                userId: props.user?.id,
                values: values,
            }, {

            });
        }}
        onKeyDown={handleKeyDown}
        //labelCol={{span: 4}}
        //wrapperCol={{span: 8}}
        labelAlign="left"
        labelWrap
        initialValues={useDefaults ? defaults : initialProvidedValues}>
        <Row gutter={{ xs: 8, md: 16 }}>
            <Col xs={6}><HelpText /></Col>
            <Col xs={18}>
                <Form.Item labelCol={labelColSide} wrapperCol={wrapperColSide} name="roles" label="Roles">
                    <Select mode="multiple" options={options} />
                </Form.Item>


                <Row style={{ paddingBottom: 16 }}>
                    <Col xs={12}>
                        <Form.Item labelCol={{span: 8}} wrapperCol={{span: 16}} label="Last Name" name="lastname">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item>
                            <Flex justify="end">
                                <Button onClick={reset} type="primary">Clear</Button>
                            </Flex>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item labelCol={labelColSide} wrapperCol={wrapperColSide} label="First Name" name="firstname">
                    <Input />
                </Form.Item>

                <Form.Item labelCol={labelColSide} wrapperCol={wrapperColSide} label="Address" name="address">
                    <Input />
                </Form.Item>

                <Form.Item labelCol={labelColSide} wrapperCol={wrapperColSide} label="City" name="city">
                    <Input />
                </Form.Item>

                <Form.Item labelCol={labelColSide} wrapperCol={wrapperColSide} label="State" name="state">
                    <Input />
                </Form.Item>

                <Form.Item labelCol={labelColSide} wrapperCol={wrapperColSide} label="Zip Code" name="zip">
                    <Input />
                </Form.Item>

                <Form.Item labelCol={labelColSide} wrapperCol={wrapperColSide} label="Phone" name="phone">
                    <Input />
                </Form.Item>
            </Col>
        </Row>

        <Row gutter={[16, 32]} style={{ paddingTop: 16 }} justify={'space-between'}>
            <Col>
                <Flex vertical>
                    <Form.Item labelCol={cellLabelCol} wrapperCol={cellWrapCol} label="Prime Cell" name="cell">
                        <Input />
                    </Form.Item>

                    <Form.Item labelCol={cellLabelCol} wrapperCol={cellWrapCol} label="Prime Carrier" name="carrier">
                        <Select options={carrierOptions} />
                    </Form.Item>

                    <Form.Item label="Use Email Prime" name="useEmailPrime" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>

                    <Form.Item name="emailPrime" hidden={!formValues.useEmailPrime}>
                        <Input />
                    </Form.Item>
                </Flex>
            </Col>

            <Col>
                <Flex vertical>
                    <Form.Item labelCol={cellLabelCol} wrapperCol={cellWrapCol} label="Alt. Cell" name="altcell">
                        <Input />
                    </Form.Item>

                    <Form.Item labelCol={cellLabelCol} wrapperCol={cellWrapCol} label="Alt. Carrier" name="altcarrier">
                        <Select options={carrierOptions} />
                    </Form.Item>

                    <Form.Item label="Use Email Alt" name="useEmailAlt" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item name="emailAlt" hidden={!formValues.useEmailAlt}>
                        <Input />
                    </Form.Item>
                </Flex>
            </Col>

            <Col>
                <Flex vertical>
                    <Form.Item labelCol={cellLabelCol} wrapperCol={cellWrapCol} label="Alt. 2 Cell" name="altcell2">
                        <Input />
                    </Form.Item>

                    <Form.Item labelCol={cellLabelCol} wrapperCol={cellWrapCol} label="Alt. 2 Carrier" name="altcarrier2">
                        <Select options={carrierOptions} />
                    </Form.Item>

                    <Form.Item label="Use Email Alt. 2" name="useEmailAlt2" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item name="emailAlt2" hidden={!formValues.useEmailAlt2!}>
                        <Input />
                    </Form.Item>
                </Flex>
            </Col>

            <Col>
                <Flex vertical>
                    <Form.Item labelCol={cellLabelCol} wrapperCol={cellWrapCol} label="Alt. 3 Cell" name="altcell3">
                        <Input />
                    </Form.Item>

                    <Form.Item labelCol={cellLabelCol} wrapperCol={cellWrapCol} label="Alt. 3 Carrier" name="altcarrier3">
                        <Select options={carrierOptions} />
                    </Form.Item>

                    <Form.Item label="Use Email Alt. 3" name="useEmailAlt3" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>

                    <Form.Item name="emailAlt3" hidden={!formValues.useEmailAlt3}>
                        <Input />
                    </Form.Item>

                </Flex>
            </Col>

        </Row>

        <br />

        <Form.Item labelCol={section3LabelCol} wrapperCol={section3WrapperCol} label="User ID" name="userid">
            <Input />
        </Form.Item>

        <Form.Item labelCol={section3LabelCol} wrapperCol={section3WrapperCol} label="Password" name="password">
            <Input />
        </Form.Item>

        <Form.Item labelCol={section3LabelCol} wrapperCol={section3WrapperCol} label="Account" name="account">
            <Input />
        </Form.Item>

        <Form.Item labelCol={section3LabelCol} wrapperCol={section3WrapperCol} hidden label="Sequence" name="sequence">
            <Input type="number" />
        </Form.Item>

        <Form.Item labelCol={section3LabelCol} wrapperCol={section3WrapperCol} label="Dealer" name="dealer">
            <Select loading={dealerNameQuery.isFetching} showSearch options={dealerNames} />
        </Form.Item>

        <Row>
            <Col xs={3}>
                <Typography.Text style={{ fontWeight: "bold" }} underline>Security Levels</Typography.Text>
                <List bordered={false} dataSource={securityLevelDescriptions} renderItem={(item) => {
                    return <Typography.Text style={{ fontWeight: "bold", display: "block" }}>{item}</Typography.Text>
                }}>
                </List>
            </Col>
            <Col xs={3}>
                <Form.Item name="security">
                    <Select options={securityLevelOptions} style={{ width: 120 }} />
                </Form.Item>
            </Col>

            <Col xs={3}>
                <Typography.Text style={{ fontWeight: "bold" }} underline>DSM Regions</Typography.Text>
                <List bordered={false} dataSource={dsmRegions} renderItem={(item) => {
                    return <Typography.Text style={{ fontWeight: "bold", display: "block" }}>{item}</Typography.Text>
                }}>
                </List>
            </Col>
            <Col xs={3}>
                <Form.Item name="region">
                    <Select style={{ width: 120 }} options={[
                        { label: "0", value: 0, },
                        { label: "1100", value: 1100 },
                        { label: "1700", value: 1700 },
                        { label: "2100", value: 2100 },
                        { label: "2900", value: 2900 },
                        { label: "3000", value: 3000 },
                        { label: "3300", value: 3300 },
                    ]} />
                </Form.Item>
            </Col>

        </Row>

        <br />



        <Form.Item labelCol={section4WrapperCol} wrapperCol={section4WrapperCol} label="Text Alarms" name="txtsend">
            <Select options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
            ]} />
        </Form.Item>

        <Row >
            <Col xs={12}>
                <Form.Item labelCol={section5LabelCol} wrapperCol={section5WrapperCol} label="Premier/Convert" name="evo">
                    <Select options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                    ]} />
                </Form.Item>
            </Col>
            <Col xs={12}>
                <Form.Item label="In Service Date" name="inservice" getValueProps={(value) => {
                    return { value: value ? dayjs(value) : "" };
                }}>
                    <DatePicker format="MM/DD/YYYY" />
                </Form.Item>
            </Col>
        </Row>

        <br />
        <br />

        <Row gutter={16}>
            <Col xs={4}>
                <Form.Item {...checkLayout} label="Check if inactive" name="linkinactive" valuePropName="checked">
                    <Checkbox onChange={(e) => {
                        if (e.target.checked) {
                            form.setFieldsValue({
                                inactiveAuthor: `${securityInfo?.firstName} ${securityInfo?.lastName}`,
                                inactiveNotes: form.getFieldValue("inactiveNotes"),
                                linkinactive: true,
                                linkinactivedate: form.getFieldValue("linkinactivedate"),
                            });
                        }
                        else {
                            form.setFieldsValue({
                                inactiveAuthor: '',
                                inactiveNotes: "",
                                linkinactivedate: "",
                                linkinactive: false,
                            })
                        }
                    }} />
                </Form.Item>

            </Col>
            <Col xs={6}>
                <Form.Item name="linkinactivedate" getValueProps={(value) => {
                    return { value: value ? dayjs(value).tz("America/Chicago", false) : "" };
                }} hidden={!formValues.linkinactive}>
                    <DatePicker format="MM/DD/YYYY" />
                </Form.Item>
            </Col>
            <Col push={2} xs={6}>
                <Form.Item label="Check to delay billing" name="donotbilluntil" valuePropName="checked">
                    <Checkbox onChange={(e) => {
                        if (e.target.checked) {
                            form.setFieldsValue({
                                delayBillAuthor: `${securityInfo?.firstName} ${securityInfo?.lastName}`,
                                delayBillNotes: form.getFieldValue("delayBillNotes"),
                                donotbilldate: form.getFieldValue("donotbilldate"),
                                donotbilluntil: true,
                            });
                        }
                        else {
                            form.setFieldsValue({
                                delayBillAuthor: '',
                                delayBillNotes: "",
                                donotbilldate: "",
                                donotbilluntil: false,
                            })
                        }
                    }} />
                </Form.Item>
            </Col>
            <Col xs={4}>
                <Form.Item name="donotbilldate" getValueProps={(value) => {
                    return { value: value ? dayjs(value).tz("America/Chicago", false) : "" };
                }} hidden={!formValues.donotbilluntil}>
                    <DatePicker format="MM/DD/YYYY" />
                </Form.Item>
            </Col>
        </Row>


        <Row style={{ paddingTop: 16 }} gutter={16}>
            <Col xs={4}>
                <Form.Item name="inactiveAuthor" hidden={!formValues.linkinactive}>
                    <Input readOnly styles={{ input: { backgroundColor: "#e9ecef" } }} />
                </Form.Item>
            </Col>
            <Col xs={6}>
                <Form.Item name="inactiveNotes" hidden={!formValues.linkinactive}>
                    <Input.TextArea rows={4} />
                </Form.Item>

            </Col>
            <Col push={2} xs={4}>
                <Form.Item name="delayBillAuthor" hidden={!formValues.donotbilluntil}>
                    <Input readOnly styles={{ input: { backgroundColor: "#e9ecef" } }} />
                </Form.Item>
            </Col>
            <Col push={2} xs={6}>
                <Form.Item name="delayBillNotes" hidden={!formValues.donotbilluntil}>
                    <Input.TextArea rows={4} />
                </Form.Item>
            </Col>
        </Row>

        <Row style={{ paddingTop: 16 }} gutter={16}>
            <Col xs={4}>

                <Form.Item<ShivversUserDTO> {...checkLayout} label="Link Replace" name="linkReplaced" valuePropName="checked">
                    <Checkbox onChange={(e) => {
                        if (e.target.checked) {
                            form.setFieldsValue({
                                linkReplaceAuthor: `${securityInfo?.firstName} ${securityInfo?.lastName}`,
                                linkReplaceNotes: form.getFieldValue("linkReplaceNotes"),
                                linkReplaceDate: form.getFieldValue("linkReplaceDate"),
                                linkReplaced: true,
                            });
                        }
                        else {
                            form.setFieldsValue({
                                linkReplaceAuthor: '',
                                linkReplaceNotes: "",
                                linkReplaceDate: "",
                                linkReplaced: false,
                            })
                        }
                    }} />
                </Form.Item>



            </Col>
            <Col xs={6}>
                <Form.Item name="linkReplaceDate" getValueProps={(value) => {
                    return { value: value ? dayjs(value).tz("America/Chicago", false) : "" };
                }} hidden={!formValues.linkReplaced}>
                    <DatePicker format="MM/DD/YYYY" />
                </Form.Item>
            </Col>
        </Row>

        <Row style={{ paddingTop: 16 }} gutter={16}>
            <Col xs={4}>
                <Form.Item name="linkReplaceAuthor" hidden={!formValues.linkReplaced}>
                    <Input readOnly styles={{ input: { backgroundColor: "#e9ecef" } }} />
                </Form.Item>
            </Col>
            <Col xs={6}>
                <Form.Item name="linkReplaceNotes" hidden={!formValues.linkReplaced}>
                    <Input.TextArea rows={4} />
                </Form.Item>

            </Col>
        </Row>

        <br />
        <br />

        <Row gutter={16}>
            <Col xs={12}>
                <Form.Item labelCol={section5LabelCol} wrapperCol={section5LabelCol} label="Hemp System" name="hemp">
                    <Select options={[{
                        label: "Yes",
                        value: "Yes",
                    },
                    {
                        label: "No",
                        value: "No",
                    },
                    ]} />
                </Form.Item>

            </Col>
            <Col xs={12}>
                <Form.Item labelCol={section5LabelCol} wrapperCol={section5WrapperCol} label="Temp Monitoring" name="tempmonitor">
                    <Select options={[{
                        label: "Yes",
                        value: "Yes",
                    },
                    {
                        label: "No",
                        value: "No",
                    },
                    ]} />
                </Form.Item>
            </Col>
        </Row>

        <Row justify={"center"} style={{ paddingTop: 16 }}>
            <Col>
                {formValues.updateIp && <ConfirmIpUpdate oldIp={externalDetailsQuery.data?.account} newIp={formValues.account} onCancel={onIpUpdateCancel}
                    onConfirm={onIpUpdateYesClick}
                />}
            </Col>
        </Row>
        <Row justify={"center"} style={{ paddingTop: 16 }}>
            <Col span={18}>
                {errorText != null && <Alert type="error" description={<p style={{whiteSpace: "pre-wrap"}}>{errorText}</p>} closable onClose={() => setErrorText(null)} />}
            </Col>
        </Row>

        <Row style={{ paddingTop: 48 }}>
            <Col push={3} xs={6}>
                <Form.Item label="Edit User" name="editUser" valuePropName="checked">
                    <Checkbox />
                </Form.Item>
            </Col>
            <Col xs={6} push={8}>
                <Space.Compact direction="horizontal" style={{ backgroundColor: "#FF0066", color: "white" }}>
                    <Form.Item noStyle name="updateIp" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <span>Check if you are updating IP address</span>
                </Space.Compact>
                <p>If this check box is checked only the IP address will update.
                    Change the IP address in the account textbox and press save.
                    You do not need to check edit user.</p>
            </Col>
        </Row>


        <Flex justify="end">
            <Form.Item hidden={formValues.updateIp}>
                <Button type="primary" htmlType="submit" form="editExternalUserForm" loading={submitFormMutation.isLoading}>
                    Save
                </Button>
            </Form.Item>
        </Flex>

    </Form>
}


interface ConfirmIpUpdateProps {
    oldIp: string | null | undefined,
    newIp: string | null | undefined,
    onConfirm: () => void;
    onCancel: () => void;
}
export const ConfirmIpUpdate = (props: ConfirmIpUpdateProps) => {

    const queryClient = useQueryClient();
    const [firstSubmitDone, setFirstSubmitDone] = useState(false);
    const isMutating = queryClient.isMutating({ mutationKey: ["updateIp"] });

    const onYesClick = useCallback(() => {
        setFirstSubmitDone(true);
    }, [setFirstSubmitDone]);

    return <Spin spinning={isMutating > 0}>
        <Alert message="Confirm Update" description={
            <div>
                <span>Double check the old and new IP addresses to confirm the update</span>
                <br />
                <br />
                <span>The old IP address is {props.oldIp}, and {props.newIp} is the new IP address.   If this is correct press submit.</span>

                <div style={{ padding: 8 }}>
                    <Flex justify="center">
                        <Button onClick={onYesClick} type="primary">Submit</Button>
                    </Flex>
                </div>
                <br />

                {firstSubmitDone && <><span>Are you sure you want to update this user?</span><Flex justify="space-around">
                    <Button type={"primary"} danger onClick={props.onConfirm}>Yes</Button>
                    <Button type={"primary"} onClick={props.onCancel}>No</Button>
                </Flex></>
                }
            </div>

        }
        /></Spin>;
}

const Search = (props: {}) => {
    const query = useQuery({
        queryKey: [{purpose: "userSearch", ...userEditScope[0]}],
        queryFn: async (q) => {
            return await ShivversService.searchUsers(undefined, q.signal);
        }
    });

    const history = useHistory();

    const options = query.data?.map(u => {
        return { ...u, value: u.externalId, label: `${u.firstName} ${u.lastName}` }
    }) ?? [];
    return <Select placeholder="Search by last, first or account" onChange={(value, option) => {
        // this is not an array!, since we didn't specify mode multiple
        HistoryUtil.push(Routes.generate(Routes.USERS_DETAIL, { id: (option as AdminUserSearchDTO).internalId }))
    }} showSearch filterOption={(input, option) => {
        return (option?.label?.toUpperCase().includes(input.toUpperCase())
            || option?.account === (input)) ?? false;

    }} style={{ width: "400px" }} loading={query.isFetching} options={options} optionRender={(option) => {
        return <div>
            <span>{option.label}</span>
            <br /><span>Account: {option.data.account}</span>
        </div>
    }} />
}