import BinInfoDTO from 'src/models/BinInfoDTO';
import * as React from 'react';
import BinApiService from 'src/api/BinApiService';
import BinDetails, { deviceQueryKeys, getUserTimezoneOffset, isAnyHeaterOn } from '../dashboard/HomeScreen/BinDetails';
import { CameraOutlined, DownloadOutlined, FileTextOutlined, RedoOutlined, WarningFilled } from '@ant-design/icons';
import { Layout, Row, Spin, Col, Button, Radio, Space, Table, Modal, Typography, message, TabsProps, Tabs, Input } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { PageHeader } from '@ant-design/pro-layout';
import { ColumnType } from 'antd/es/table';
import PingAndBinDTO from 'src/models/PingAndBinDTO';
import Link from 'antd/es/typography/Link';
import Routes from 'src/consts/Routes';
import dayjs from 'dayjs';
import { get } from 'lodash-es';
import { SortOrder } from 'antd/es/table/interface';
import { downloadAsciiBinState } from '../dashboard/BinStatusPage/BinVisualThree';
import BinDTO from 'src/models/BinDTO';
import { CameraImage } from '../dashboard/Camera/CameraImage';
import { RefreshButton, getCurrentModeDescription } from '../dashboard/BinStatusPage/BinStatsPage';
import { formatNumber } from '../dashboard/BinStatusPage/HeaterControls';
import { SignalRContext } from 'src/app/App';
import { useBinStateFromAzure } from 'src/queries/useBinStateFromAzure';
import { CSVLink } from "react-csv";
import { ApiError } from 'src/api/ApiResultHandler';
import { useQueryParam, StringParam} from 'use-query-params';
import FanOffReason from 'src/consts/FanOffReason';
import { ExtraOfflineInfoContext } from '../dashboard/BinOfflineIndicator';
import { PremierSpreadsheet } from './PremierSpreadsheet';
import { useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE_TIME_MS } from '../users/UserList';
import RoleUtil from 'src/utils/RoleUtil';

// interface State {
//     bins?: BinInfoDTO[];
//     loading: boolean;
//     refreshDelay: boolean;
// }

export const binDBKeys = {
    all: ['binDB'] as const,
    allBin: () => [...binDBKeys.all, "Bins"] as const,
    bin: (id: number) => [...binDBKeys.all, id] as const,
    binInfo: (binId: number) => [...binDBKeys.bin(binId), "binInfo"] as const,
    dristackmoduletwin: (binId: number) => [...binDBKeys.bin(binId), "dristackmodule"] as const,
    livestream: {
        all: (binId: number) => [...binDBKeys.bin(binId), "livestream"] as const,
        camera: (options: {binId: number, cameraNumber: number}) => [...binDBKeys.livestream.all(options.binId), {cameraNumber: options.cameraNumber}] as const,
    },
    calculateGrainHeightName: (binId: number) => [...binDBKeys.bin(binId), "calculateGrainHeight"] as const,
    calculateGrainHeight: (binId: number, params: {eaveHeightFt: number | null, peakHeightFt: number | null}) => 
        [...binDBKeys.calculateGrainHeightName(binId), {eaveHeightFt: params.eaveHeightFt, peakHeightFt: params.peakHeightFt}] as const,
    shivversBin: (ip: string) => [...binDBKeys.all, ip] as const,
    shivversBinInfo: (externalId: number) => [...binDBKeys.all, "shivvers", externalId, "bininfo"] as const,
    moistureDisplay: (ip: string) => [...binDBKeys.shivversBin(ip), "moistureDisplay"] as const,
    alertDataShivvers: (ip: string) => [...binDBKeys.shivversBin(ip), "alertDataShivvers"] as const,
    shivversSettingsHistory: (ip: string) => [...binDBKeys.shivversBin(ip), "dryerSettings"] as const,
}


export const useAllBinsQuery = (enabled: boolean = true, options: {selector?: (data: BinInfoDTO[]) => BinInfoDTO[]} = {}) => {
    const query = useQuery(binDBKeys.allBin(), (q) => BinApiService.getAllBins(q.signal), {
        enabled: enabled,
        select: (data) => {
            if (options?.selector == null) {
                return data;
            }
            return options.selector(data);
        },
        onError: (err) => {
            console.error(err);
        },
    },
    )
    return query;
}

enum ViewType {
    Cards = "Cards",
    CSV = "CSV",
}

export const ProductTypeQueryParamName = 'product';

export const BinCommander = () => {
    // const initialState: State = { loading: false, refreshDelay: false };
    // const [state, setState] = useState<State>(initialState);
    const queryClient = useQueryClient();

    const binQuery = useAllBinsQuery();

    const setLoading = useCallback((value: boolean, i: number) => {
        // todo
    }, []);

    const [viewType, setViewType] = useQueryParam('viewType', {...StringParam, default: ViewType.CSV});
    const [productType, setProductType] = useQueryParam(ProductTypeQueryParamName, {...StringParam, default: 'shivvers'});
    const [showExtraOfflineInfoForBins, setShowExtraOfflineInfoForBins] = useState(false);

    const items: TabsProps['items'] = [
        {
          key: 'shivvers',
          label: 'Shivvers',
          children: <PremierSpreadsheet />,
        }];
    if (RoleUtil.currentUserIsAdmin()) {
        items.push(
            {
                key: 'autobin',
                label: 'AutoBin',
                children: <SpreadsheetView productType={productType!} />,
                },
                {
                key: 'dristack',
                label: 'DriStack',
                children: <SpreadsheetView productType={productType!} />,
                },
        );
    }

    const getBins = (binLength: number, bins: BinInfoDTO[] | null | undefined) => {
        if (bins) {
            return (
                bins.map((bin: BinInfoDTO, i: number) => (
                    <BinDetails
                        bin={bin}
                        offlineReport={null}
                        binLength={binLength}
                        targetValue={null}
                        key={bin.name ?? bin.deviceId ?? bin.id}
                        id={i}
                        isLoading={setLoading} />
                )));
        } else {
            return <></>;
        }
    };

    return (
        <Layout.Content className="dashboard">

<Spin spinning={binQuery.isLoading} >
                <ExtraOfflineInfoContext.Provider value={{ showExtraOfflineInfo: showExtraOfflineInfoForBins, toggleOfflineInfo: () => setShowExtraOfflineInfoForBins(!showExtraOfflineInfoForBins) }}>

            <Tabs type='line' onChange={(activeKey) => setProductType(activeKey)} activeKey={productType!} items={items} tabBarExtraContent={{
                right: <Row gutter={[32, 16]}>
                <Col>
                    <Button ghost={true}
                        type="primary" size="large" disabled={binQuery.isFetching} onClick={(event: any) => {
                            
                            queryClient.cancelQueries(binDBKeys.all);
                            queryClient.cancelQueries(deviceQueryKeys.all);

                            queryClient.invalidateQueries(binDBKeys.all);
                            queryClient.invalidateQueries(deviceQueryKeys.all);

                        }}><RedoOutlined spin={binQuery.isFetching} /></Button>
                </Col></Row>
            }} />
                    {/* {viewType === ViewType.Cards && <Row align="top" gutter={{ xs: 8, sm: 8, md: 16, lg: 16, xl: 16 }} justify="space-between">
                        {
                            // this.binArr
                            getBins(binQuery.data?.length!, binQuery.data)
                        }
                    </Row>
                    } */}
                    {/* {viewType === ViewType.CSV && ['autobin', 'dristack'].includes(binSource!) && <SpreadsheetView productType={binSource!} />}
                    {viewType === ViewType.CSV && binSource === "premier" && <PremierSpreadsheet />} */}
                </ExtraOfflineInfoContext.Provider>

            </Spin>
        </Layout.Content>
    );

};


const interestStyle = {
    backgroundColor: "#EEFF33 ",
    color:"black",
    fontWeight: "bold",
};
const warningStyle = {
    backgroundColor: "#FFB6C1",
    color: "black",
    fontWeight: "bold",
};
const okStyle = {
    backgroundColor: "#FFFFFF",
    color: "green",
    fontWeight: "bold",
}

type DataIndexRequired<P,> = NonNullable<ColumnType<P>['dataIndex']>;

export const sortBoolean = <P,>(params: { a: P, b: P, sortOrder: SortOrder, dataIndex: string | string[]  }) => {

    const sorter = (a: P, b: P, sortOrder: SortOrder) => {

        let aValue = get(a, params.dataIndex);
        let bValue = get(b, params.dataIndex);
        if (aValue != null && bValue != null) {
            if (aValue < bValue) return -1;
            if (bValue < aValue) return 1;
        }
        else if (aValue == null) {
            // That means a is null , so b will come first.

            if (sortOrder === "descend") {
                return -1;
            }
            else {
                return 1;
            }
        }
        else if (bValue == null) {
            // this means b is null, a will be first
            if (sortOrder === "descend") {
                return 1;
            }
            else {
                return -1;
            }
        }
        return 0;
    };

    return sorter(params.a, params.b, params.sortOrder);
}

export const sortNumberic = <P,>(params: { a: P, b: P, sortOrder: SortOrder, dataIndex: string | string[]  }) => {

    const sorter = (a: P, b: P, sortOrder: SortOrder) => {

        let aValue = get(a, params.dataIndex);
        let bValue = get(b, params.dataIndex);
        if (aValue != null && bValue != null) {
            if (aValue < bValue) return -1;
            if (bValue < aValue) return 1;
        }
        else if (aValue == null) {
            // That means a is null , so b will come first.

            if (sortOrder === "descend") {
                return -1;
            }
            else {
                return 1;
            }
        }
        else if (bValue == null) {
            // this means b is null, a will be first
            if (sortOrder === "descend") {
                return 1;
            }
            else {
                return -1;
            }
        }
        return 0;
    };

    return sorter(params.a, params.b, params.sortOrder);
}


export const sortISODate = <P,>(params: { a: P, b: P, sortOrder: SortOrder, dataIndex: string | string[] }) => {

    // works for ISO8601 dates
    return sortString({ a: params.a, b: params.b, sortOrder: params.sortOrder, dataIndex: params.dataIndex });
}

export const sortString = <P,>(params: { a: P, b: P, sortOrder: SortOrder, dataIndex: string | string[]  }) => {

    const sorter = (a: P, b: P, sortOrder: SortOrder) => {

        let aValue = get(a, params.dataIndex);
        let bValue = get(b, params.dataIndex);
        if (aValue != null && bValue != null) {
            return aValue?.localeCompare?.(bValue) ?? 0;
        }
        else if (aValue == null) {
            // That means a is null , so b will come first.

            if (sortOrder === "descend") {
                return -1;
            }
            else {
                return 1;
            }
        }
        else if (bValue == null) {
            // this means b is null, a will be first
            if (sortOrder === "descend") {
                return 1;
            }
            else {
                return -1;
            }
        }
        // both are null
        return 0;
    };

    return sorter(params.a, params.b, params.sortOrder);
}

export const useRequestNewBinStateMutation = (deviceId: string) => {
    return useMutation({
        mutationFn: async () => {
            return await BinApiService.uploadBinStateToAzure(deviceId);
        }
    })
}

export interface ExtendedColumnType<P> extends ColumnType<P> {
    sortType?: 'number' | 'string' | 'date' | 'boolean'
}

export interface ColumnExpansionObj<P, S> {
    columns: Array<ExtendedColumnType<P>>;
    datasource: S,
}
export const useColumnExpansion = <P extends object, S extends object>(params: ColumnExpansionObj<P, S>) => {

    let newColumns = params.columns.map((column) => {

        let newColumn = { ...column };
        if (column.dataIndex == null) {
            console.warn("bail: ", column.title, "has no dataIndex");
            return newColumn;
        }

        // derive default
        if (column.sorter == null) {
            if (column.sortType === 'number') {
                newColumn.sorter = (a, b, sortOrder) => sortNumberic({ a, b, sortOrder: sortOrder!, dataIndex: column.dataIndex as string });
            }
            else if (column.sortType == 'date') {
                // assume locale compare, assume ISO 8601 string
                newColumn.sorter = (a, b, sortOrder) => sortISODate({ a, b, sortOrder: sortOrder!, dataIndex: column.dataIndex as string | string[] });

            }
            else if (column.sortType == 'boolean') {
                newColumn.sorter = (a, b, sortOrder) => sortBoolean({ a, b, sortOrder: sortOrder!, dataIndex: column.dataIndex as string | string[] });
            }
            else if (column.sortType == 'string') {
                newColumn.sorter = (a, b, sortOrder) => sortString({ a, b, sortOrder: sortOrder!, dataIndex: column.dataIndex as string | string[] });
            }
            else {
                return newColumn;
            }

        }
        return newColumn;
    })
    return newColumns;
}

interface ShowCameraButtonProps {
    binId: number;
    binName: string
}
export const ShowCameraButton = (props: ShowCameraButtonProps) => {

    const [open, setOpen] = useState(false);

    return (<>
        <Modal title={`Camera for ${props.binName}`} open={open} onCancel={() => setOpen(false)} onOk={() => setOpen(false)}>
            <CameraImage binId={props.binId} />
        </Modal>
        <Button style={{ minWidth: "64px" }} icon={<CameraOutlined />} onClick={() => setOpen(true)}></Button>
    </>);

}

interface SpreadsheetDataSource extends BinDTO {
    binId: number | null | undefined;
    binName: string;
    deviceId: string | null ;
    loading: boolean | null | undefined;
    online: boolean | null | undefined;
}


const formatOnlineStatus = (value: boolean | null | undefined) => {
    let renderText = '';
    if (value === false) {
        renderText = "Offline";
    }
    else if (value === true) {
        renderText = "Online";
    }

    return renderText;

}

export const SpreadsheetView = (props: {productType: string}) => {
    const [nameSearch, setNameSearch] = useState("");
    const nameSearchDelayedValue = useDebouncedCallback((value) => {
        setNameSearch(value);
    }, DEBOUNCE_TIME_MS);

    const queryClient = useQueryClient();
    const binsQuery = useAllBinsQuery(true, {selector(data) {
        if (data == null) {
            return data;
        }
        return data.filter(bin => bin.automationType?.toLocaleLowerCase?.() === props.productType);
    },});

    // https://stackoverflow.com/a/68066447
    const csvLinkRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null); // setup the ref that we'll use for the CSVLink click once we've updated the filename

    const formatYesNoBoolean = (value: boolean | null | undefined) => {
        let renderText = '';
        if (value === false) {
            renderText = "No";
        }
        else if (value === true) {
            renderText = "Yes";
        }

        return renderText;

    }

    const formatOnOffBoolean = (value: boolean | null | undefined) => {
        let renderText = '';
        if (value === false) {
            renderText = "Off";
        }
        else if (value === true) {
            renderText = "On";
        }

        return renderText;

    }

    const formatOnOffPaused = (bdto: BinDTO | null | undefined) => {
        
        if(bdto === null || bdto === undefined){
            return "";
        }

        if(bdto?.fanOperations?.offReason === null){
            return "On";
        }else if(bdto?.fanOperations?.offReason === FanOffReason.Requested){
            return "Off";
        }else{
            return "Paused";
        }
    }

    // const formatOnOffPausedFan = (bdto: BinDTO | null | undefined) => {
    //     let renderText = '';
    //     if (bdto?.fanOperations?.offReason?.toString() === "Paused"){
    //         renderText = "Paused";
    //     }else if (value===true){
    //         renderText = "";
    //     }

    //     return renderText;
    // }

    const formatDateTime = (value: string | null | undefined) => {

        if (value == null) {
            return value;
        }
        return dayjs(value)?.format("L LT");
    }

    useEffect(() => {
        binsQuery.data?.forEach(bin => {
            try {
                SignalRContext.invoke("SubscribeBin", bin.id);
                console.log(`subscribed to bin ${bin.id}`);
            } catch (error) {
                console.error("error subscribing to bin", error);
            }
        });

        return () => {
            binsQuery.data?.forEach(bin => {
                SignalRContext.invoke("UnsubscribeBin", bin.id);
                console.log(`unsubsribed from bin ${bin.id}`);
            });
        };
    }, [binsQuery.data ?? null]);

    const snapshotsData = useQueries({
        queries: binsQuery.data?.filter(binInfo => !(binInfo.deviceId == null) || !(binInfo.id == null)).map(binInfo => {
            const userTimezoneOffset = getUserTimezoneOffset();
            return {
                queryKey: deviceQueryKeys.devicePing(binInfo.deviceId!), queryFn: async (q: any) => {
                    const result = await BinApiService.pingDeviceAndGetBinDTOFromAzure(binInfo.deviceId!, userTimezoneOffset, q.signal);
                    // share to bin detail from azure
                    if (result.binDTO != null) {
                        queryClient.setQueryData([...deviceQueryKeys.stateFromAzure(binInfo.deviceId!)], result.binDTO);
                    }
                    return result;
                }
            };
        }) ?? []
    });

    const pending = snapshotsData.reduce((acc, obj) => { return acc + Number(obj.isLoading) }, 0);

    // We cached the data above, and the infinite staletime will only read from cache OR refetch on cache invalidation (logout, signalr)
    const deviceQueries = useQueries({
        queries: snapshotsData.map?.((data, index) => {
            const deviceId = binsQuery.data?.[index]?.deviceId!;
            const userTimezoneOffset = getUserTimezoneOffset();
            return {
                staleTime: Infinity, queryKey: deviceQueryKeys.stateFromAzure(binsQuery.data?.[index]?.deviceId!),
                queryFn: async (q: any) => {
                    return await BinApiService.getBinDetailFromAzure(deviceId, binsQuery.data?.[index]?.id!, userTimezoneOffset, q.signal);
                },
                enabled: data.data != null,
            };
        })
    });
    const columns: Array<ExtendedColumnType<SpreadsheetDataSource>> = [
        {
            dataIndex: "binName",
            title: "Bin Name",
            width: "16ch",
            fixed: "left",
            defaultSortOrder: 'ascend',
            sortType: 'string',
            render(value, record, index) {
                return <div>
                    <Link href={`${Routes.BIN_STATS}/${record.binId}`} target="_blank">{value}</Link>
                </div>
            },
        },
        {
            dataIndex: "growerName",
            title: "Grower",
            width: "16ch",
            fixed: "left",
            defaultSortOrder: 'ascend',
            sortType: 'string',
        },
        {
            dataIndex: "online",
            title: "Status",
            width: "72px",
            sortType: 'boolean',
            render(value, record, index) {
                if (record.loading === true) {
                    return <Spin spinning />;
                }
                let warning = false;
                if (value === false) {
                    warning = true;
                }

                let renderText = formatOnlineStatus(record?.online);


                return {
                    props: {
                        style: { ...(warning ? warningStyle : okStyle) },
                    },
                    children: <div>{renderText}</div>
                }
            },
        },
        {
            dataIndex: "captureTimeUtc",
            title: "Last Updated",
            width: "160px",
            sortType: 'date',
            onCell: (data, index) => {
                let value = data.captureTimeUtc;
                if (data.captureTimeUtc == null) {
                    return {};
                }
                else {
                    let warning = false;
                    let datetime = dayjs(value);
                    if (datetime.isValid()) {
                        let currentTime = dayjs();
                        let diff = currentTime.diff(datetime, 'hours');
                        if (diff > 24 || diff < -24) {
                            warning = true;
                        }
                    }
                    return {
                        style: { ...(warning ? warningStyle : undefined) },
                    }
                }
            },
            render(value, record, index) {
                if (value == null) {
                    return <span></span>;
                }

                return <span>{formatDateTime(value)}</span>;
            },
        },
        {
            title: "Mode",
            dataIndex: ['operatingMode'],
            sortType: "string",
        },
        {
            dataIndex: "isFanOn",
            sortType: "boolean",
            width: "80px",
            title: "Fan",
            render(value, record, index) {
                let warning = false;
                if (value === false) {
                    warning = true;
                }

                let renderText = formatOnOffPaused(record);

                let someStyle= okStyle;
                if(renderText === "Paused"){
                    someStyle=interestStyle;
                }else if(renderText === "Off"){
                    someStyle=warningStyle;
                }
                return {
                    props: {
                        style: someStyle,
                    },
                    children: <div>{renderText}</div>
                }
            },
        },
        {
            dataIndex: "isAnyHeaterOn",
            sortType: "boolean",
            width: "88px",
            title: "Heater",
            render(value, record, index) {
                let warning = false;
                if (value === false) {
                    warning = true;
                }
                let renderText = formatOnOffBoolean(value);


                return {
                    props: {
                        style: { ...(warning ? warningStyle : okStyle) },
                    },
                    children: <div>{renderText}</div>
                }
            },
        },
        {
            dataIndex: ["compressorState", 'isOn'],
            sortType: "boolean",
            title: "Compressor",
            render(value, record, index) {
                let warning = false;
                if (value === false) {
                    warning = true;
                }

                let renderText = formatOnOffBoolean(value);


                return {
                    props: {
                        style: { ...(warning ? warningStyle : okStyle) },
                    },
                    children: <div>{renderText}</div>
                }
            },
        },
        {
            title: <span>Fill Lv. <br />Sensor</span>,
            dataIndex: ['grain', 'percentFullCalculated'],
            sortType: "number",
            render(value, record, index) {
                return formatNumber(value, { decimalPlaces: 0, filler: "", suffix: "%" });
            },
        },
        {
            title: <span>Fill Lv. <br />Entered</span>,
            dataIndex: ['grain', 'percentFullEntered'],
            sortType: "number",
            render(value, record, index) {
                return formatNumber(value, { decimalPlaces: 0, filler: "", suffix: "%" });
            },
        },
        {
            dataIndex: "ambientTemp",
            title: "Ambient Temp",
            sortType: 'number',
            render(value, record, index) {
                return formatNumber(value, { decimalPlaces: 1, filler: "", suffix: "" });
            },
        },
        {
            dataIndex: ['plenumAir', 'temp'],
            title: "Plenum Temp",
            sortType: 'number',
            render(value, record, index) {
                return formatNumber(value, { decimalPlaces: 1, filler: "", suffix: "" });
            },
        },
        {
            title: <Typography.Text>Temp Δ < br /> <Typography.Text type='secondary'>ambient v. plenum</Typography.Text></Typography.Text>,
            ellipsis: true,
            dataIndex: 'plenumAmbientTmperatureDifference',
            sortType: 'number',
            showSorterTooltip: {
                title: "Temp difference (ambient v. plenum)",
            },
            render(value, record, index) {
                let warning = false;
                if (value >= 30 || value <= -30) {
                    warning = true;
                }

                let formattedValue = formatNumber(value, { decimalPlaces: 1, filler: "" });


                return {
                    props: {
                        style: warning ? warningStyle : undefined
                    },
                    children: <span>{formattedValue}</span>
                }
            }
        },
        {
            title: "CO2",
            dataIndex: "cO2Level",
            width: "72px",
            sortType: 'number',
            render(value, record, index) {
                let warning = false;
                if (value > 2000) {
                    warning = true;
                }


                return {
                    props: {
                        style: warning ? warningStyle : undefined
                    },
                    children: <div>{value}</div>
                }
            },
        },
        {
            dataIndex: "hasCamera",
            title: "Camera",
            width: "88px",
            render(value, record, index) {
                if (record.loading === true) {
                    return null;
                }
                if (!record.hasCamera) {
                    return null;
                }
                return {
                    props: {},
                    children: <Row justify='center'>
                        <Col>
                            <ShowCameraButton binId={record.id} binName={record.binName} />
                        </Col>
                    </Row>
                }
            },
        },
        {
            dataIndex: 'ascii',
            ellipsis: true,
            sorter: true,
            sortDirections: [],
            showSorterTooltip: {
                title: "Diagnostic File",
            },
            title: <Typography.Text style={{ fontWeight: "bold", fontSize: 12 }}>Diagnostic <br /> File</Typography.Text>,
            render(value, record, index) {
                if (record.loading === true) {
                    return null;
                }
                return {
                    props: {},
                    children: <Row justify='center'>
                        <Col span={24}>
                            <Button icon={<FileTextOutlined />} style={{ width: "100%", overflow: 'hidden' }} onClick={() =>
                                downloadAsciiBinState(record!)}></Button>
                        </Col>
                    </Row>
                }
            },
        },

        {
            title: "Refresh",
            width: "72px",
            render(value, record, index) {

                return <>
                    <RequestNewBinStateButton deviceId={record?.deviceId!} />
                </>
            },
        },
        {
            width: "80px",
            title: <span><Typography.Text>System</Typography.Text> <br /> <Typography.Text>Type</Typography.Text></span>,
            ellipsis: true,
            showSorterTooltip: {
                title: "System Type",
            },
            dataIndex: ["automationType"],
            sortType: "string",
        },

    ];

    const dataSources: Array<any> = deviceQueries?.map((bin, index) => {

        const snapshotQuery: typeof snapshotsData[0] | undefined = snapshotsData?.[index];
        let plenumAmbientTemperatureDiff: number | string | null = null;
        if (bin?.data?.plenumAir?.temp == null) {
            //plenumAmbientTemperatureDiff = "No plenum data";
            plenumAmbientTemperatureDiff = null;
        }
        else if (bin?.data?.ambientAir?.temp == null) {
            //plenumAmbientTemperatureDiff = "No Ambient data";
            plenumAmbientTemperatureDiff = null;
        }
        else {
            plenumAmbientTemperatureDiff = roundTo(bin?.data?.plenumAir?.temp - bin?.data?.ambientAir?.temp, 2);
        }
        let isAnyHeaterOnValue = isAnyHeaterOn(bin.data!);
        return {
            ...(bin.data ?? {}),
            binId: binsQuery.data?.[index].id,
            binName: binsQuery.data?.[index]?.name,
            growerName: binsQuery.data?.[index]?.growerName,
            deviceId: binsQuery.data?.[index]?.deviceId!,
            online: snapshotQuery?.data?.ping,
            onlineFormatted: formatOnlineStatus(snapshotQuery?.data?.ping),
            loading: snapshotQuery?.isLoading,
            cO2Level: bin?.data?.cO2Level,
            captureTimeUtc: bin?.data?.captureTimeUtc,
            captureTimeFormatted: formatDateTime(bin?.data?.captureTimeUtc! as string | null | undefined),
            ambientTemp: bin?.data?.ambientTemp,
            plenumAmbientTmperatureDifference: plenumAmbientTemperatureDiff,
            isAnyHeaterOn: isAnyHeaterOnValue,
            isAnyHeaterOnFormatted: formatOnOffBoolean(isAnyHeaterOnValue),
            isFanOnFormatted: formatOnOffPaused(bin?.data),
            isCompressorOnFormatted: formatOnOffBoolean(bin?.data?.compressorState?.isOn),
            operatingMode: getCurrentModeDescription(bin?.data!),
            hasCameraFormatted: formatYesNoBoolean(bin?.data?.hasCamera),
            binLink: `${window.location.origin}/${Routes.BIN_STATS}/${binsQuery?.data?.[index]?.id}`,
        }
    });

    let transformed = useColumnExpansion({ columns: columns, datasource: dataSources });

    const generateCSVFilename = useCallback(() => {

        return `binOverview ${dayjs().format("YYYY-MM-DD HH_mm_ss")}.csv`;
    }, []);

    const updateFilters = () => {
        if (nameSearch === "") {
            return dataSources ?? [];
        }

        const filteredTable = dataSources
            ?.filter((record) => record.binName?.toUpperCase().includes(nameSearch.toUpperCase()))
            ?? [];
        return filteredTable;
    };

    const finalTable = useMemo(() => updateFilters(), [nameSearch, dataSources]);


    return (<>
        <Space direction="horizontal" size='large'>
            <div>Bins still loading: {pending}</div>

            <Input style={{ width: 240 }} onChange={(evt) => nameSearchDelayedValue(evt.target.value)} placeholder='Search by Name'></Input>

            <Button>
                <CSVLink ref={csvLinkRef} filename={generateCSVFilename()}
                onClick={() => {
                    let downloadAttr = csvLinkRef.current?.link;
                    if (downloadAttr) {
                        downloadAttr.download = generateCSVFilename();
                    }
                }}
                 data={dataSources} headers={[
                    { label: "Bin Name", key: "binName" },
                    { label: "Grower", key: "growerName" },
                    { label: "Status", key: "onlineFormatted" },
                    //{label: "Last Updated", key: "captureTimeUtc"}, // format
                    { label: "Last Updated", key: "captureTimeFormatted" }, // format
                    { label: "Mode", key: "operatingMode" },
                    { label: "Fan", key: "isFanOnFormatted" },
                    { label: "Heater", key: "isAnyHeaterOnFormatted" },
                    { label: "Compressor", key: "isCompressorOnFormatted" },
                    { label: "Fill Level (%) Sensor", key: "grain.percentFullCalculated" },
                    { label: "Fill Level (%) Entered", key: "grain.percentFullEntered" },
                    { label: "Ambient Temp", key: "ambientTemp" },
                    { label: "Plenum Temp", key: "plenumAir.temp" },
                    { label: "Temp Difference ambient v. plenum", key: "plenumAmbientTmperatureDifference" },
                    { label: "CO2", key: "cO2Level" },
                    { label: "Camera", key: "hasCameraFormatted" },
                    { label: "System Type", key: "automationType" },
                    { label: "Bin page link", key: "binLink" },
                    { label: "Loading", key: "loading" },
                ]}>Download CSV</CSVLink>
            </Button>
        </Space>
        <Table style={{ paddingTop: "16px" }} rowKey={'binId'} dataSource={finalTable} columns={transformed} pagination={{ defaultPageSize: 200, size: "default" }}
            scroll={{ x: '100%' }}
            sticky size="small"
        >

        </Table>
    </>);
}

interface requestNewBinStateButtonProps {
    deviceId: string;
}

export const RequestNewBinStateButton = (props: requestNewBinStateButtonProps) => {

    const requestNewBinStateMutationMutation = useRequestNewBinStateMutation(props.deviceId!);


    return <>
        <Button disabled={requestNewBinStateMutationMutation.isLoading} type='primary' icon={<RedoOutlined spin={requestNewBinStateMutationMutation.isLoading} />} onClick={async () => {
            //console.log("clicked record for: ", {record});
            const res = await BinApiService.uploadBinStateToAzure(props.deviceId!);
            requestNewBinStateMutationMutation.mutate(undefined, {
                onSuccess(data, variables, context) {
                    if (data?.success === true) {
                        console.log("requested new binstate");
                    }
                    else {
                        message.error("request for a new binstate failed");
                    }
                },
                onError(error, variables, context) {
                    message.error("error requesting a new bin state");
                },
            })
            if (res.success === true) {
                console.log("requested new binstate");
            }
        }}></Button>
    </>;
}

export default BinCommander;