import { useQuery, useQueryClient } from "@tanstack/react-query"
import { Button, Card, Flex, Modal } from "antd"
import React, { useState } from "react"
import ShivversService from "src/api/ShivversService";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { useBinInfoContext } from "src/queries/BinInfoContext";
import { GetShivversDrySettings, SettingsTable } from "./SettingsTable";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const DryerSettingsCard = () => {

    const binInfo = useBinInfoContext();
    const [showTableSettings, setShowTableSettings] = useState(false);
    const queryClient = useQueryClient();
    console.log("rerender dryersettingscard");

    queryClient.prefetchQuery({
        queryKey: binDBKeys.shivversSettingsHistory(binInfo.linkIpAddress!),
        queryFn: async (q) => GetShivversDrySettings(q.queryKey[1] as string, q.signal),
    });

    const query = useQuery({
        queryFn: async (q) => await ShivversService.paragraph(binInfo.linkIpAddress!, q.signal),
        queryKey: [...binDBKeys.shivversBin(binInfo.linkIpAddress!), "shivversParagraph"],
    });

    return <>
        <Card
            loading={query.isInitialLoading}
            title="Dryer Settings"
            style={{ width: "100%" }}
            styles={{
                body: {
                    padding: 0,
                    minWidth: "296px",
                    width: "100%",
                    minHeight: "504px",
                }
            }
            }
            extra={
                <Button onClick={() => {
                    setShowTableSettings(true);
                }}>History</Button>}>
            <div style={{ width: "100%" }}>
                <Flex justify="center" style={{ width: "100%" }}>
                    <DryerSettingsText text={query.data?.text} />
                </Flex>
            </div>
        </Card>

        <Modal title="Dryer Settings History"
            styles={{ body: { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' } }}
            width={"70%"}
            footer={null}
            open={showTableSettings} onOk={() => {
                setShowTableSettings(false)
            }} onCancel={() => {
                setShowTableSettings(false)
            }}>
            <SettingsTable />
        </Modal>
    </>

}

export const DryerSettingsText = (props: { text: string | null | undefined }) => {

    return <>
        <CopyToClipboard text={props.text ?? ''}>
            <div
                style={{
                    width: "100%",
                    minHeight: "504px",
                    whiteSpace: 'preserve',
                    cursor: 'copy',
                    textAlign: "center", resize: "none",
                    border: 0
                }}
            >
                <span>{props.text ?? ''}</span>
            </div>
        </CopyToClipboard>
    </>
}
